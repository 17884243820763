import add from 'date-fns/add'

import {
    ARTICLE_IDS_COOKIE_NAME,
    DATE_WINDOW_STARTED_COOKIE_NAME,
    ALLOWANCE_FINISHED_COOKIE_NAME,
    ARTICLE_WINDOW_MONTHS,
} from 'lib/freeArticleAllowance/consts'
import type { Cookies, SetCookie } from 'lib/useCookies'
import { captureException } from 'shared/sentry'

/*

    Just a simple wrapper around the cookies to 
    encode/decode the cookie values we need consistently

    NOTE: It is the job of this controller to guarantee
    the return types for the functions, so that we can
    confidently work with them elsewhere

*/

const getEndOfWindowTimestamp = (): Date => {
    // assuming the window has just started, what is the timestamp
    // for the end of the window in ms
    const now = new Date()
    return add(now, { months: ARTICLE_WINDOW_MONTHS })
}

class ArticleAllowanceCookieClient {
    cookies: null | Cookies = null
    setCookie: null | SetCookie = null

    constructor(cookies: Cookies, setCookie: SetCookie) {
        this.cookies = cookies
        this.setCookie = setCookie
    }

    // internal helpers

    _getCookie = (cookieName: string): string | null => {
        const cookieValue = this.cookies && this.cookies[cookieName]
        return cookieValue || null
    }

    _checkIfMatchesType = (
        decodedValue: any,
        expectedType: string,
        cookieName: string,
    ): boolean => {
        // Simple function to check type and handle error
        // Won't be the best choice for every single type, so use your own logic if required
        if (typeof decodedValue !== expectedType) {
            captureException(new Error(`"${cookieName}" was not set to a "${expectedType}"`), {
                errorInfo: {
                    decodedCookieValue: decodedValue,
                },
            })
            return false
        }

        return true
    }

    // getters

    getIdsCompleted = (): string[] | null => {
        const cookieName = ARTICLE_IDS_COOKIE_NAME
        const cookieValue = this._getCookie(cookieName)

        if (!cookieValue) return null

        const decodedValue = JSON.parse(cookieValue)

        if (!Array.isArray(decodedValue)) {
            // if not an array...hasn't been set properly somehow
            captureException(new Error(`"${cookieName}" was not set to an array`), {
                errorInfo: {
                    decodedCookieValue: decodedValue,
                },
            })
            return null
        }

        return decodedValue
    }

    getDateWindowStartedCookie = (): Date | null => {
        const cookieName = DATE_WINDOW_STARTED_COOKIE_NAME
        const cookieValue = this._getCookie(cookieName)

        if (!cookieValue) return null

        const decodedValue = JSON.parse(cookieValue)

        const wasTimestampReturned = this._checkIfMatchesType(decodedValue, 'number', cookieName)

        return wasTimestampReturned ? new Date(decodedValue) : null
    }

    getAllowanceFinishedCookie = (): boolean | null => {
        const cookieName = ALLOWANCE_FINISHED_COOKIE_NAME
        const cookieValue = this._getCookie(cookieName)

        if (!cookieValue) return null

        const decodedValue = JSON.parse(cookieValue)

        return this._checkIfMatchesType(decodedValue, 'boolean', cookieName) ? decodedValue : null
    }

    // setters
    setIdsCompleted = (idsCompleted: string[]): void => {
        const cookieName = ARTICLE_IDS_COOKIE_NAME
        const encodedValue = JSON.stringify(idsCompleted)
        const expiry = getEndOfWindowTimestamp()

        this.setCookie &&
            this.setCookie(cookieName, encodedValue, {
                expires: expiry,
                path: '/',
            })
    }

    setDateWindowStartedCookie = (): void => {
        const cookieName = DATE_WINDOW_STARTED_COOKIE_NAME
        const expiry = getEndOfWindowTimestamp()

        const nowTimestampMs = new Date().getTime()

        const encodedValue = JSON.stringify(nowTimestampMs)

        this.setCookie &&
            this.setCookie(cookieName, encodedValue, {
                expires: expiry,
                path: '/',
            })
    }

    setAllowanceFinishedCookie = (expiry: Date): void => {
        const cookieName = ALLOWANCE_FINISHED_COOKIE_NAME
        const encodedValue = JSON.stringify(true)

        // Doesn't really matter what we set this to, since we rely on the expirys

        this.setCookie &&
            this.setCookie(cookieName, encodedValue, {
                expires: expiry,
                path: '/',
            })
    }
}

export default ArticleAllowanceCookieClient
