/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TrackAppsflyerPBAEventInput = {
    customerUserId: string;
    afUserId: string;
    eventName: string;
    afRevenue?: number | null | undefined;
    afCurrency?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type trackAppsFlyerPBAEventMutationVariables = {
    input: TrackAppsflyerPBAEventInput;
};
export type trackAppsFlyerPBAEventMutationResponse = {
    readonly trackAppsflyerPbaEvent: {
        readonly clientMutationId: string | null;
    } | null;
};
export type trackAppsFlyerPBAEventMutation = {
    readonly response: trackAppsFlyerPBAEventMutationResponse;
    readonly variables: trackAppsFlyerPBAEventMutationVariables;
};



/*
mutation trackAppsFlyerPBAEventMutation(
  $input: TrackAppsflyerPBAEventInput!
) {
  trackAppsflyerPbaEvent(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackAppsflyerPBAEventPayload",
    "kind": "LinkedField",
    "name": "trackAppsflyerPbaEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackAppsFlyerPBAEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackAppsFlyerPBAEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ec6e3a14e646496ce9e40c90b980197",
    "id": null,
    "metadata": {},
    "name": "trackAppsFlyerPBAEventMutation",
    "operationKind": "mutation",
    "text": "mutation trackAppsFlyerPBAEventMutation(\n  $input: TrackAppsflyerPBAEventInput!\n) {\n  trackAppsflyerPbaEvent(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '24adfc7803377a9ccac2faae063c6762';
export default node;
