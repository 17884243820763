import isFunction from 'lodash/isFunction'
import { commitMutation } from 'react-relay'
import type { PayloadError, MutationConfig, Environment } from 'relay-runtime'

import { wafCaptchaErrorMessage } from 'modules/Captcha/consts'
import { captureException } from 'shared/sentry'

// Executes a mutation
// and returns its results as a Promise.
const createMutationPromise =
    <P extends Object>(relayEnv: Environment) =>
    (mutationParams: MutationConfig<any>) =>
        new Promise<P>((resolve, reject) => {
            //@ts-ignore
            commitMutation(relayEnv, {
                ...mutationParams,
                onCompleted: (response: any, errors: any) => {
                    if (isFunction(mutationParams.onCompleted)) {
                        mutationParams.onCompleted(response, errors)
                    }

                    if (errors) {
                        const defaultError = {
                            message: 'Oops, something went wrong. Please try again later.',
                        }
                        const fmzError =
                            errors.find((e: any) => 'message' in e) ||
                            errors.find((e: any) => 'debug' in e) ||
                            defaultError

                        reject(fmzError)
                    } else {
                        resolve(response)
                    }
                },
                onError: (_err?: PayloadError) => {
                    if (!_err) {
                        captureException(
                            new Error(
                                'Relay Mutation returned error case, but no error was passed to catch block',
                            ),
                        )
                    } else if (_err.message === wafCaptchaErrorMessage) {
                        reject({ message: wafCaptchaErrorMessage })
                    } else {
                        captureException(_err)
                    }

                    reject({
                        message: 'We are experiencing network issues. Please try again later.',
                    })
                },
            })
        })

export default createMutationPromise
