import React from 'react'

const AppleTouch = '/images/AppIcons/apple-touch-icon.png'
const Favicon16 = '/images/AppIcons/favicon-16x16.png'
const Favicon32 = '/images/AppIcons/favicon-32x32.png'

const AppIcons = [
    <link key="apple-touch-icon" rel="apple-touch-icon" sizes="180x180" href={AppleTouch} />,
    <link key="favicon-32" rel="icon" type="image/png" sizes="32x32" href={Favicon32} />,
    <link key="favicon-16" rel="icon" type="image/png" sizes="16x16" href={Favicon16} />,
    <meta key="meta-theme" name="theme-color" content="#ffffff" />,
]

export default AppIcons
