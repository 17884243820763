import { useEffect } from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { configureScope as sentryConfigureScope } from '@sentry/nextjs'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ReactRelayContext } from 'react-relay'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import SEO from '../next-seo.config'

import AppIcons from 'components/AppIcons'
import Box from 'components/Primitives/Box'
import { setCheckoutDataParams, trackPageView } from 'lib/analyticsApi'
import analyticsClient from 'lib/analyticsClient'
import { useEnvironment } from 'lib/createRelayEnvironment'
import { FreeArticleCookiesProvider } from 'lib/freeArticleAllowance/useFreeArticleCookies'
import { checkAuthenticationFromRelayProps } from 'lib/handleAuth'
import { DatadogProvider } from 'lib/tracking/datadog/datadogClient'
import { FinanceadsProvider } from 'lib/tracking/financeAds/useFinanceadsTracking'
import { ImpactProvider } from 'lib/tracking/impact/useImpactTracking'
import { getCookie } from 'lib/useCookies'
import CookieModal from 'modules/CookieModal'
import { CONSENT_COOKIE_NAME } from 'modules/CookieModal/consts'
import { NavBarProvider } from 'modules/Navigation/useIsNavCollapsed'
import Theme, { global } from 'theme'

const GOOGLE_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID as string
const appleAuthRedirectURI = `https://${process.env.NEXT_PUBLIC_BASE_DOMAIN}/login`

const GlobalStyle = createGlobalStyle`${global}`

declare global {
    interface Window {
        // https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-challenge-api-set-token-domain.html
        awsWafCookieDomainList: string[] | undefined
        // for sending messages to oban's webview
        ReactNativeWebView: {
            postMessage(msg: string): void
        }
    }
}

if (typeof window !== 'undefined') {
    window.awsWafCookieDomainList = ['finimize.com']
}

const MyApp = ({ Component, pageProps }: AppProps) => {
    // makes the assumption that if we're logged in, we're using a query with `me`.
    // seems like a fair assumption as we use the same logic to redirect if not logged in
    const isAuthenticated = checkAuthenticationFromRelayProps(pageProps)

    const environment = useEnvironment({ initialRecords: pageProps.queryRecords, context: null })

    const relay = {
        environment: environment,
        refetch: undefined,
        hasMore: undefined,
    }

    const router = useRouter()

    analyticsClient.init()

    // Set up analytics
    // Needs to be run client-side as relies on router object
    useEffect(() => {
        const trackPageViews = () => {
            trackPageView()

            setCheckoutDataParams()
            router.events.on('routeChangeComplete', () => {
                trackPageView()
                //params for checkout tracking & redirect behaviour
                setCheckoutDataParams()
            })
        }

        trackPageViews()

        // Check if cookies have already been enabled
        const consentCookie = getCookie(CONSENT_COOKIE_NAME)
        const persistUser = consentCookie === 'true'
        if (persistUser) analyticsClient.authorize()
    }, [router.events])

    // Check if Javascript is enabled and inform 3rd parties if necessary
    useEffect(() => {
        // Function to check if JavaScript is enabled
        const isJavaScriptEnabled = () => {
            return document && document.body.classList.contains('js-enabled')
        }

        // Add a custom tag to Sentry
        sentryConfigureScope(scope => {
            // I've struggled to get this sending a false value in all cases, even if tag is added to server-side config
            // so tag may usually only show when it is set to true. False here probably means a partial JS failure.
            scope.setTag('javascript_enabled', isJavaScriptEnabled() ? true : false)
        })
    }, [])

    const AWS_WAF_SCRIPT = process.env.NEXT_PUBLIC_AWS_WAF_SCRIPT
    const APPSFLYER_WEB_DEV_KEY = process.env.NEXT_PUBLIC_APPSFLYER_WEB_DEV_KEY
    const APPSFLYER_SMART_BANNER_KEY = process.env.NEXT_PUBLIC_APPSFLYER_SMART_BANNER_KEY
    return (
        <DatadogProvider>
            <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                <ThemeProvider theme={Theme}>
                    <FreeArticleCookiesProvider>
                        <NavBarProvider>
                            <ImpactProvider>
                                <FinanceadsProvider>
                                    <Head>
                                        {AppIcons}
                                        {/* GSC (Google Search Console) profile as given by Reddico */}
                                        <meta
                                            name="google-site-verification"
                                            content="g0QOVK1RFFFSjX4mmVuBgdlLYp3i4NJJE7mRqHeEW5Q"
                                            key="google-site-verification-1"
                                        />

                                        {/* Existing GSC profile */}
                                        <meta
                                            name="google-site-verification"
                                            content="INKTkZkqR9XeUPzAiis0nduMI0InBra30oMnrsiFH_U"
                                            key="google-site-verification-2"
                                        />
                                        {/* TODO: Put keys in publicRuntimeConfig */}
                                        <meta
                                            name="appleid-signin-client-id"
                                            content="com.finimize.lagavulin"
                                        />
                                        <meta name="appleid-signin-scope" content="name email" />

                                        <meta
                                            name="appleid-signin-redirect-uri"
                                            content={appleAuthRedirectURI}
                                        />

                                        <meta name="appleid-signin-use-popup" content="true" />
                                    </Head>
                                    <GlobalStyle />
                                    {/** AppsFlyer Web SDK script (with Smart Banner) */}
                                    <Script
                                        id="appsflyer-pba-script"
                                        strategy="afterInteractive"
                                        type="text/javascript"
                                    >
                                        {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
                                            (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
                                            t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
                                            o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+
                                            (i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF",
                                            "pba,banners",{pba: {webAppId: "${APPSFLYER_WEB_DEV_KEY}", measurementStatus:false}, banners: {key: "${APPSFLYER_SMART_BANNER_KEY}"}})
                                            ;AF('banners', 'showBanner');`}
                                    </Script>
                                    {/**
                                     * This script adds Google Tag Manager
                                     */}
                                    <Script id="gtm-script" strategy="afterInteractive">
                                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                            })(window,document,'script','dataLayer','GTM-WJJD448');`}
                                    </Script>
                                    {/** AWS WAF script used to provide challenges or captchas to specific traffic
                                     * https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-challenge-api.html
                                     * For now I've added globally since eventually it might be good to ensure all
                                     * traffic is from a browser
                                     *
                                     * Uses afterInteractive since this is the closest match to the recommended "defer"
                                     */}
                                    <Script
                                        strategy="afterInteractive"
                                        type="text/javascript"
                                        src={AWS_WAF_SCRIPT}
                                    />
                                    {/**
                                     * This script is used to add a class to the body to indicate that JS is enabled
                                     */}
                                    <Script id="js-enable-script" strategy="afterInteractive">
                                        {`document.body.classList.add('js-enabled');`}
                                    </Script>

                                    {/* Global seo settings */}
                                    <DefaultSeo {...SEO} />

                                    <ReactRelayContext.Provider
                                        value={{ environment: environment }}
                                    >
                                        <Box height="100%" width="100%">
                                            <Component
                                                {...pageProps}
                                                {...{ relay }}
                                                isAuthenticated={isAuthenticated}
                                            />
                                        </Box>
                                    </ReactRelayContext.Provider>

                                    {/* Current fix for hiding builder preview pages due to re-render issue for modals */}
                                    {/* If the CookieModal hasn't been loaded (because the user has already accepted/declined)
                                    then it shouldn't be rendered. */}
                                    {!router.asPath.includes('builder.preview') && CookieModal && (
                                        <CookieModal />
                                    )}
                                </FinanceadsProvider>
                            </ImpactProvider>
                        </NavBarProvider>
                    </FreeArticleCookiesProvider>
                </ThemeProvider>
            </GoogleOAuthProvider>
        </DatadogProvider>
    )
}

export default MyApp
