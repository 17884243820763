import React from 'react'

import styled, { css } from 'styled-components'

import type { ButtonBaseProps } from './ButtonBase'
import ButtonBase from './ButtonBase'

import type { ColorName, ThemedComponent } from 'theme'

export type ButtonVariant = 'primary' | 'secondary'

export type ButtonProps = Omit<ButtonBaseProps, 'iconColor' | 'as' | 'disabledIconColor'> & {
    /**
     * @type {ButtonVariant} - Default 'primary'. Selects the style variant for the button.
     */
    variant?: ButtonVariant
}

// Experimental way to get typing for theme
type StyledProps = ThemedComponent<ButtonProps>

const StyledButton = styled(ButtonBase)<StyledProps>`
    ${({ variant, theme }) => {
        switch (variant) {
            case 'primary':
                return css`
                    background-color: ${theme.palette.accent[500]};
                    color: ${theme.palette.neutral[0]};
                    &:active {
                        background-color: ${theme.palette.primary[600]};
                    }
                    &:disabled {
                        color: ${theme.palette.neutral[0]};
                        background-color: ${theme.palette.primary[200]};
                    }
                `
            case 'secondary':
            default:
                return css`
                    color: ${theme.palette.neutral[999]};
                    background-color: ${theme.palette.neutral[0]};
                    &:active {
                        background-color: ${theme.palette.primary[100]};
                    }
                    &:disabled {
                        color: ${theme.palette.neutral[300]};
                        background-color: ${theme.palette.neutral[0]};
                    }
                `
        }
    }}
`

const Button = ({ variant = 'primary', ...props }: ButtonProps) => {
    let iconColor: ColorName = 'neutral.0'

    if (variant === 'primary') iconColor = 'neutral.0'
    if (variant === 'secondary') iconColor = 'neutral.999'

    return <StyledButton iconColor={iconColor} variant={variant} {...props} />
}

export default Button
