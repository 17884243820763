export enum DatadogEnv {
    DEV = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export interface EnvSpecificConfig {
    /**
     * The percentage of sessions to be sent to datadog. 100 for all, 0 for none.
     */
    sessionSampleRate: number
    /**
     * The percentage of sessions to be recorded. 100 means all sessions are recorded.
     *
     * @note: this has pricing implications.
     */
    sessionReplaySampleRate: number
    /**
     * Enables automatic collection of user actions.
     */
    trackUserInteractions: boolean
    /**
     * Enables automatic collection of user frustrations. Implies `trackUserInteractions: true`.
     */
    trackFrustrations: boolean
    /**
     * Enables collection of resource events.
     */
    trackResources: boolean
    /**
     * Enables collection of long task events.
     */
    trackLongTasks: boolean
    /**
     * Enables ability to conditionally start session replay recording.
     */
    startSessionReplayRecordingManually?: boolean
}
