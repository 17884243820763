import type { ReactElement } from 'react';
import React from 'react'

import { ImQuotesLeft } from 'react-icons/im'

import Paragraph from 'components/Paragraph'
import type { BoxProps } from 'components/Primitives/Box';
import Box from 'components/Primitives/Box'
import StarRating from 'components/StarRating'

interface Props {
    review: string
    rating?: number
    title?: string
    author?: string
    quoteColor?: BoxProps['color']
    yellowStars?: boolean
    boxStyle?: BoxProps
}

export const ReviewCard = (props: Props): ReactElement => {
    const {
        review,
        rating = 5,
        title,
        author,
        quoteColor = 'primary.600',
        yellowStars = false,
        boxStyle = {},
    } = props
    return (
        <Box
            backgroundColor="grey300"
            borderRadius="default"
            padding={['1rem', null, '2rem']}
            display="flex"
            flexDirection="column"
            height="100%"
            {...boxStyle}
        >
            <Box as={ImQuotesLeft} mb="1rem" fontSize={40} color={quoteColor} />
            <Box flex={1}>
                {title && (
                    <Paragraph color="black800" fontWeight={700}>
                        {title}
                    </Paragraph>
                )}
                <Paragraph color="black800" mt="-0.5rem">
                    {review}
                </Paragraph>
            </Box>
            <StarRating
                rating={rating}
                color={yellowStars ? 'warning.500' : 'grey800'}
                mt="1.5rem"
                mb="0.5rem"
            />
            {author && (
                <Paragraph fontSize="13px" mb="0" color="neutral.700">
                    {author}
                </Paragraph>
            )}
        </Box>
    )
}
