// Standard Events
export const COMPLETE_REGISTRATION = 'CompleteRegistration'
export const SUBSCRIBE = 'Subscribe'
export const START_TRIAL = 'StartTrial'
export const PURCHASE = 'Purchase'
export const SEARCH = 'Search'

// Custom Events
export const COMPLETE_APP_REGISTRATION = 'Complete App Registration'
export const APP_STORE_CLICK = 'AppStoreClick'
export const NEWSLETTER_EMAIL_SUBMITTED = 'NewsletterEmailSubmitted'
