import { isProductionEnvironment } from 'utils/isProductionEnvironment'

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
// In staging, set release as preview url to allow filtering by PR
export const SENTRY_RELEASE = isProductionEnvironment
    ? process.env.NEXT_PUBLIC_SENTRY_RELEASE
    : process.env.NEXT_PUBLIC_BASE_DOMAIN

export const ignoreErrors = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // We are getting UnhandledRejection errors when Outlook users click on links in newsletters, due to the
    // Safe Links feature in Outlook. See https://docs.microsoft.com/en-us/microsoft-365/security/office-365-security/atp-safe-links?view=o365-worldwide
    // It is recommended to filter out this string rather than the more broad `Non-Error promise rejection captured`
    // here: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
    // because this exact string is used in CefSharp, which is used by the Outlook desktop app.
    // https://github.com/cefsharp/CefSharp/blob/445fb9ab2d178b55e3ca6561722d151ce3d9337e/CefSharp/Internals/JavascriptObjectRepository.cs#L293
    'Object Not Found Matching Id',
]

export const denyUrls = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
]
