import { graphql } from 'react-relay'

const checkUser = graphql`
    query checkUserQuery {
        viewer {
            me {
                id
                hasPremiumSubscription
            }
        }
    }
`

export default checkUser
