import type { ElementType } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import styled from 'styled-components'
import {
    space,
    layout,
    compose,
    color,
    flexbox,
    typography,
    border,
    background,
    position,
    system,
    boxShadow,
    grid,
} from 'styled-system'
import type {
    SpaceProps,
    ColorProps,
    WidthProps,
    HeightProps,
    DisplayProps,
    FontSizeProps,
    FontWeightProps,
    FontFamilyProps,
    LineHeightProps,
    LetterSpacingProps,
    FontStyleProps,
    TextAlignProps,
    FlexProps,
    AlignItemsProps,
    JustifyItemsProps,
    JustifyContentProps,
    FlexDirectionProps,
    FlexWrapProps,
    AlignSelfProps,
    AlignContentProps,
    BorderProps as BrderProps,
    BorderBottomProps,
    BorderTopProps,
    BorderLeftProps,
    BorderRightProps,
    BorderColorProps,
    BorderStyleProps,
    BorderRadiusProps,
    BorderWidthProps,
    ZIndexProps,
    TopProps,
    BottomProps,
    RightProps,
    LeftProps,
    PositionProps as PosProps,
    BackgroundProps as BGProps,
    BackgroundImageProps,
    BackgroundColorProps,
    BackgroundPositionProps,
    BackgroundRepeatProps,
    BackgroundSizeProps,
    MinWidthProps,
    MinHeightProps,
    MaxWidthProps,
    MaxHeightProps,
    ResponsiveValue,
    BoxShadowProps,
    GridProps,
    GridGapProps,
    GridColumnGapProps,
} from 'styled-system'

import type { RadiiProps } from 'components/types/props'
import type { ColorName, ThemedComponent } from 'theme'
import { getColor, radii } from 'theme'

export type TypographyProps = FontWeightProps &
    FontSizeProps &
    FontFamilyProps &
    LineHeightProps &
    LetterSpacingProps &
    FontStyleProps &
    TextAlignProps

export type FlexBoxProps = FlexProps &
    AlignItemsProps &
    JustifyItemsProps &
    JustifyContentProps &
    FlexDirectionProps &
    FlexWrapProps &
    AlignSelfProps &
    AlignContentProps

export type GridBoxProps = GridProps & GridGapProps & GridColumnGapProps

export type BorderProps = BrderProps &
    BorderBottomProps &
    BorderTopProps &
    BorderLeftProps &
    BorderRightProps &
    BorderColorProps &
    BorderStyleProps &
    BorderRadiusProps &
    BorderWidthProps

export type BackgroundProps = BGProps &
    BackgroundImageProps &
    BackgroundColorProps &
    BackgroundPositionProps &
    BackgroundRepeatProps &
    BackgroundSizeProps

export type PositionProps = ZIndexProps & TopProps & BottomProps & RightProps & LeftProps & PosProps

export type LayoutProps = WidthProps &
    HeightProps &
    DisplayProps &
    MinHeightProps &
    MaxHeightProps &
    MinWidthProps &
    MaxWidthProps

export type CSSProps = {
    objectFit?: ResponsiveValue<React.CSSProperties['objectFit']>
    overflow?: ResponsiveValue<React.CSSProperties['overflow']>
    transform?: ResponsiveValue<React.CSSProperties['transform']>
}

type CustomColorProps = {
    color?: ColorName
    borderColor?: ColorName
    backgroundColor?: ColorName
    bg?: ColorName
}

type _BoxProps = SpaceProps &
    ColorProps &
    TypographyProps &
    FlexBoxProps &
    BorderProps &
    BackgroundProps &
    PositionProps &
    LayoutProps &
    BoxShadowProps &
    CSSProps &
    GridBoxProps &
    React.HTMLAttributes<any> & {
        as?: ElementType
        className?: string
        css?: any
    }

export type BoxProps = Omit<
    _BoxProps,
    'color' | 'backgroundColor' | 'borderColor' | 'bg' | 'borderRadius' | 'url' | 'href'
> &
    CustomColorProps & {
        borderRadius?: ResponsiveValue<RadiiProps | undefined>
    }

export type BoxPropsWithTheme = ThemedComponent<BoxProps>

const objectFit = system({
    objectFit: true,
    transform: true,
})

export const _getColor = ({ color }: any) => getColor(color)

export const shouldForwardProp = (propName: string) => {
    if (
        // These props are not passed through as they have custom values (e.g. neutral.999)
        // which are handled by the Box component (i.e. converted into valid HTML values)
        propName === 'color' ||
        propName === 'backgroundColor' ||
        propName === 'borderColor' ||
        propName === 'bg' ||
        propName === 'borderRadius'
    ) {
        return false
    }
    // Forward the prop if it is a valid HTML attribute with valid values
    return isPropValid(propName)
}

const Box = styled.div.withConfig({
    shouldForwardProp: prop => shouldForwardProp(prop),
})<BoxProps>`
    box-sizing: border-box;
    & {
        ${compose(
            space,
            typography,
            color,
            layout,
            flexbox,
            border,
            background,
            position,
            objectFit,
            boxShadow,
            grid,
        )}
    }
    & {
        border-radius: ${({ borderRadius }) =>
            typeof borderRadius === 'string' && radii[borderRadius]};
        color: ${({ color }) => getColor(color)};
        background-color: ${({ backgroundColor, bg }) => getColor(bg ?? backgroundColor)};
        border-color: ${({ borderColor }) => getColor(borderColor)};
    }
`

export default Box
