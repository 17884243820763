import GA from 'react-ga4'
import type { UaEventOptions } from 'react-ga4/types/ga4'

import { captureException } from 'shared/sentry'
import { isProductionEnvironment } from 'utils/isProductionEnvironment'

const IS_BROWSER = typeof window !== 'undefined'
const GOOGLE_ANALYTICS_TAG_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID as string

if (!GOOGLE_ANALYTICS_TAG_ID) {
    throw new Error(`Analytics Client has not found Google Analytics tag ID.`)
}

class GATrackingClient {
    // NOTE All methods on this class which contact the API MUST be gated
    // with a check on `isAuthorized`. Similarly all setup code can only
    // be run in the `authorize` method which configures this property.

    isAuthorized = false

    authorize = () => {
        // Comment out the following line to enable GA tracking in development / staging
        if (!isProductionEnvironment) return

        if (this.isAuthorized) return
        this.isAuthorized = true

        // @ts-ignore
        if (IS_BROWSER && !window.ga) {
            GA.initialize(GOOGLE_ANALYTICS_TAG_ID)
        }
    }

    _handleEvent = (trackingFn: Function) => {
        if (!this.isAuthorized) return
        try {
            trackingFn()
        } catch (e) {
            captureException(e)
        }
    }

    track = (options: UaEventOptions) => {
        if (!this.isAuthorized) return
        const event = () => GA.event(options)
        this._handleEvent(event)
    }

    pageView = (path?: string) => {
        if (!this.isAuthorized) return

        const pathFromUrl = window && window.location.pathname + window.location.search
        const route = path || pathFromUrl

        const event = () => GA.send({ hitType: 'pageview', page: route })
        this._handleEvent(event)
    }
}

const googleAnalyticsTrackingClient = new GATrackingClient()
export default googleAnalyticsTrackingClient
