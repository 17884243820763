import styled from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ThemedComponent } from 'theme/Types'

type StyledProps = ThemedComponent<BoxProps>

/**
 * InlineButton is a button that appears in text content. It is styled like a hyperlink but only has an onClick.
 */
const InlineButton = styled(Box)<StyledProps>`
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary[500]};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    &:hover {
        text-decoration: underline;
    }
`

export default InlineButton
