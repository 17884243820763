import React from 'react'

import styled, { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import Theme from 'theme'

export type Spacing = number | number[]

type LocalSetProps = {
    children: React.ReactNode
    isVertical?: boolean
    spacing?: Spacing
    isBlock?: boolean //only works with isVertical=true
}

export type SetProps = LocalSetProps &
    Omit<
        BoxProps,
        | 'children'
        // The margin props are used internally to create responsive spacing,
        // so we don't want to be able to pass them in
        | 'margin-left'
        | 'margin-top'
        | 'margin-bottom'
        | 'margin-right'
        | 'margin'
        | 'marginX'
        | 'marginY'
        | 'm'
        | 'mx'
        | 'my'
        | 'mt'
        | 'mr'
        | 'mb'
        | 'ml'
    >

const Set = React.forwardRef(
    (
        {
            className,
            children,
            isVertical = false,
            isBlock = false,
            spacing = 1,
            ...props
        }: SetProps,
        ref,
    ) => (
        <_Set
            className={className}
            spacing={spacing}
            isVertical={isVertical}
            isBlock={isBlock}
            ref={ref}
            {...props}
        >
            {children}
        </_Set>
    ),
)

Set.displayName = 'Set'

export default Set

/**
 * This is to support responsive spacing on Set component. It works now but I'm sure there is a better way to do this.
 *
 * @param spacing spacing scale. Can be a single number or an array of numbers to support responsive spacing
 * @param cssString css styling to be applied to the spacing scale as well as the other css styles
 * Use {{scale}} to represent the spacing scale in the cssString. e.g. margin-left: {{scale}}rem;
 * @returns css styling with the responsive spacing applied
 */
const createResponsiveSpacing = (spacing: Spacing | undefined, cssString: string) => {
    if (!spacing) return

    if (!Array.isArray(spacing)) {
        return css`
            ${cssString.replace(/{{scale}}/g, spacing.toString())};
        `
    }

    const callback = (spacingScale: number, index: number) =>
        css`
            @media screen and (min-width: ${Theme.breakpoints[index]}) {
                ${cssString.replace(/{{scale}}/g, spacingScale.toString())}
            } ;
        `

    const smallestScreen = css`
        @media screen and (min-width: 0px) {
            ${cssString.replace(/{{scale}}/g, spacing[0].toString())}
        } ;
    `

    return `
        ${smallestScreen}${spacing.map(callback).join('')}
    `
}

const _Set = styled(Box)<SetProps>`
    display: flex;
    ${props => {
        if (props.isVertical) {
            return css`
                flex-direction: column;
                ${!props.isBlock && `align-items: ${props.alignItems || 'flex-start'};`}
            `
        }
        const cssString = `
            margin-left: -{{scale}}rem;
            margin-top: -{{scale}}rem;
            align-items: ${props.alignItems || 'center'};
            justify-content: ${props.justifyContent || 'flex-start'};
        `
        return createResponsiveSpacing(props.spacing, cssString)
    }}

    & > * {
        ${({ spacing, isVertical }) => {
            if (spacing === undefined) return
            const cssStr = isVertical
                ? '&:not(:last-child) {margin-bottom: {{scale}}rem;}'
                : 'margin-left: {{scale}}rem; margin-top: {{scale}}rem;'

            return createResponsiveSpacing(spacing, cssStr)
        }}
    }
`
