import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { trackImpactEventMutationResponse } from '__generated__/trackImpactEventMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

type InputObject = {
    eventName: string
    userId: string
    clickId?: string
    orderSubtotalPostDiscount?: number
    orderDiscount?: number
    currencyCode?: string
    itemSubTotal?: number
    itemCategory?: string
}

const trackImpactEventMutation = (relayEnv: Environment) => (input: InputObject) => {
    return createMutationPromise<trackImpactEventMutationResponse>(relayEnv)({
        // @ts-ignore
        mutation: graphql`
            mutation trackImpactEventMutation($input: TrackImpactEventInput!) {
                trackImpactEvent(input: $input) {
                    clientMutationId
                }
            }
        `,
        variables: { input },
    })
}

export default trackImpactEventMutation
