import mixpanel from 'mixpanel-browser'

import { captureException } from 'shared/sentry'
import { isProductionBuild } from 'utils/isProductionBuild'

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
const proxyRootUrl = process.env.NEXT_PUBLIC_PROXY_ROOT_URL

type GenericProperties = { [key: string]: any }

if (!mixpanelToken) {
    throw new Error(`Analytics Client has not found mixpanel token.`)
}

const getApiHostURL = () => {
    // Get staging or production proxy url
    if (proxyRootUrl) {
        return proxyRootUrl
    }
    // In case that prod environments do not find proxy, throw error to sentry
    if (process.env.NODE_ENV == 'production') {
        captureException(new Error('Analytics Client has not found proxy.'))
    }
    // Return undefined in case of dev environment, route events straight
    // to client.
    return undefined
}

/**
 * TODO Only need this for the experiment agency Journey Further to send events to Mixpanel
 * so we can properly track experiment results. We can remove this afterwards
 * I _think_ the main downside here is it means Mixpanel will be imported on every page.
 * I think we must be doing this everywhere anyway, because we at least track page views
 * if not other events. So hopefully as a temporary measure it's worth the upside
 */
if (typeof window !== 'undefined') {
    // This will run the callback when the browser is idle
    const callback = () => {
        // @ts-ignore
        window.mixpanel = mixpanel
    }

    // Support older browsers
    if ('requestIdleCallback' in window) {
        // Requestidle callback will run at least within 2 seconds
        requestIdleCallback(callback, { timeout: 2000 })
    } else {
        setTimeout(callback, 500) // shorter delay as fallback since this is the minimum
    }
}

class AnalyticsClient {
    /* All methods on this class which contact the API MUST be gated
     with a check on `hasAcceptedCookies`. Similarly all setup code can only
     be run in the `authorize` method which configures this property. */

    hasCalledInit = false
    hasAcceptedCookies = false

    /* This won't start sending events until `init` has also been called,
       when we're on the client (i.e. not SSR) *and* the user is logged in.
       This should not be called after a mixpanel cookie has been set. */
    authorize = () => {
        if (this.hasAcceptedCookies) return
        this.hasAcceptedCookies = true
        if (!this.hasCalledInit) return

        // replaces any existing cookies
        return mixpanel.set_config({ disable_persistence: false })
    }

    /* This will start sending events but the user is anonymous (no cookies are stored)
    until they log in, sign up or accept cookies */
    init = () => {
        if (this.hasCalledInit) return

        if (!mixpanelToken) {
            throw new Error(`Analytics Client has not found mixpanel token.`)
        }

        this.hasCalledInit = true

        return mixpanel.init(mixpanelToken, {
            disable_persistence: true, // this can be enabled when cookies are accepted
            api_host: getApiHostURL(),
            // debug: true, // uncomment to see more info about events in browser console (very useful for debugging)
        })
    }

    /* Makes sure we have initted before running any event,
    and also catch any errors without interupting use */
    _handleEvent = (trackingFn: Function) => {
        try {
            if (!isProductionBuild) {
                console.count('📫 Sending event to Mixpanel')
            }
            trackingFn()
        } catch (e) {
            captureException(e)
        }
    }

    // Get user agent for debugging
    userAgent = global.navigator?.userAgent

    mandatoryProps = {
        channel: 'web',
        "User Agent": this.userAgent,
    }

    // Send and event name with no properties
    track = (eventName: string) => {
        const event = () => mixpanel.track(eventName, this.mandatoryProps)
        this._handleEvent(event)
    }

    // Track event with properties
    trackWithProperties = (eventName: string, props: GenericProperties) => {
        const event = () =>
            mixpanel.track(eventName, {
                ...props,
                ...this.mandatoryProps,
            })
        this._handleEvent(event)
    }

    // set people properties
    // You should pass in any props with spacing, e.g:
    // { 'Has Robo': true }
    // Note: this will not work for anonymous users unless they end up logging in / signing up
    // there is an option to make an additional identify call with the user's distinct_id, but
    // MP recommends against this due to identity merge issues.
    set = (props: GenericProperties) => {
        const event = () => mixpanel.people.set(props)
        this._handleEvent(event)
    }

    // Set to people properties - cannot be overwritten
    setOnce = (props: GenericProperties) => {
        const event = () => mixpanel.people.set_once(props)
        this._handleEvent(event)
    }

    increment = (eventName: string, value: number) => {
        const event = () => mixpanel.people.increment(eventName, value)
        this._handleEvent(event)
    }

    // identify on login
    identify = (identity: string) => {
        const event = () => mixpanel.identify(identity)
        this._handleEvent(event)
    }

    // Reset identify on log out
    reset = () => {
        const event = () => mixpanel.reset()
        this._handleEvent(event)
    }

    // alias on signup. Do not call alias multiple times with the same user.
    // TODO: this is now legacy, we should remove this and use identify instead
    alias = (identity: string) => {
        const event = () => mixpanel.alias(identity)
        this._handleEvent(event)
    }

    // Register super properties that fire on every event, optionally specify for how long to keep these properties, in days
    register = (props: GenericProperties, days?: number) => {
        const event = () => mixpanel.register(props, days)
        this._handleEvent(event)
    }
}

const analyticsClient = new AnalyticsClient()

export default analyticsClient
