/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TrackImpactEventInput = {
    userId: string;
    eventName: string;
    clickId?: string | null | undefined;
    orderSubTotalPostDiscount?: number | null | undefined;
    orderDiscount?: number | null | undefined;
    currencyCode?: string | null | undefined;
    itemSubTotal?: number | null | undefined;
    itemCategory?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type trackImpactEventMutationVariables = {
    input: TrackImpactEventInput;
};
export type trackImpactEventMutationResponse = {
    readonly trackImpactEvent: {
        readonly clientMutationId: string | null;
    } | null;
};
export type trackImpactEventMutation = {
    readonly response: trackImpactEventMutationResponse;
    readonly variables: trackImpactEventMutationVariables;
};



/*
mutation trackImpactEventMutation(
  $input: TrackImpactEventInput!
) {
  trackImpactEvent(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackImpactEventPayload",
    "kind": "LinkedField",
    "name": "trackImpactEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackImpactEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackImpactEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "38374ab8ed049fef450ca285db06af74",
    "id": null,
    "metadata": {},
    "name": "trackImpactEventMutation",
    "operationKind": "mutation",
    "text": "mutation trackImpactEventMutation(\n  $input: TrackImpactEventInput!\n) {\n  trackImpactEvent(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ace4195f14b6b455b1a4c2211436476b';
export default node;
