import { useState, useCallback, useEffect, useMemo } from 'react'

import constate from 'constate'
import { useRouter } from 'next/router'

import { IMPACT_CLICK_ID_PARAM, SIGNUP } from './consts'
import type { ImpactTrackingFn } from './types'

import { useCookies, DAY_IN_SECONDS } from 'lib/useCookies'
import trackImpactEventMutation from 'relay/mutations/trackImpactEvent'

/**
 * This hook is used to be able to track events in Impact.
 * It should be used in components where we want to track events, e.g. in the signup flow.
 *
 * Use it as follows:
 * - get the client: impactClient = useImpactContext()
 * - call the track method (or pass it as a prop to child components), e.g. impactClient.track(environment, eventName, data)
 */
const useImpactTracking = () => {
    // NOTE All methods which contact the API MUST be gated
    // with a check on `isAuthorized`.
    const router = useRouter()
    const { query: params = {} } = router
    const { cookies, setCookie } = useCookies()
    const [isAuthorized, setIsAuthorized] = useState(false)

    const impactClickId = useMemo(() => {
        if (cookies && cookies[IMPACT_CLICK_ID_PARAM]) {
            return cookies[IMPACT_CLICK_ID_PARAM]
        }
        return undefined
    }, [cookies])

    useEffect(() => {
        if (!isAuthorized) return

        // If the tracking client is authorised and we don't have a click ID in the
        // cookies already, check the query params for a click ID and set the cookie
        if (isAuthorized && !impactClickId) {
            Object.entries(params).forEach(([key, val]) => {
                if (key === IMPACT_CLICK_ID_PARAM && typeof val === 'string') {
                    const cookieKeyName = IMPACT_CLICK_ID_PARAM

                    const options = {
                        maxAge: 30 * DAY_IN_SECONDS, // 30 days
                        path: '/',
                    }

                    const decodedVal = decodeURIComponent(val)

                    setCookie(cookieKeyName, decodedVal, options)
                }
            })
        }
    }, [isAuthorized, impactClickId])

    /**
     * This method is called when the user has given consent to tracking, which should only happen in CookieModal.
     * It sets the `isAuthorized` flag to true, which allows us to set a cookie and send events to Impact.
     */
    const authorize = useCallback(() => {
        if (isAuthorized) return
        setIsAuthorized(true)
    }, [isAuthorized])

    /**
     * Sends a mutation to our backend with the event name and data. The backend then sends the event to Impact.
     */
    const track = useCallback<ImpactTrackingFn>(
        async (environment, eventName, data) => {
            if (!isAuthorized) return
            // We need to provide a consistent user ID to Impact so that they can link events together
            if (!data.userId) return
            // Parent (i.e. signup) event has to have a click ID
            if (eventName === SIGNUP && !impactClickId) return

            await trackImpactEventMutation(environment)({
                ...data,
                userId: data.userId,
                clickId: impactClickId,
                eventName,
            })
        },
        [isAuthorized, impactClickId],
    )

    return { authorize, track, impactClickId, isAuthorized }
}

export const [ImpactProvider, useImpactContext] = constate(useImpactTracking)
