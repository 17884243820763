/*

    Global SEO config settings

*/

import type { DefaultSeoProps } from 'next-seo/lib/types'

import { SEO_DOMAIN } from 'consts'

const title = 'Finimize: Invest smarter'
const description =
    'Become a smarter investor with daily finance news, investing ideas, opportunities, and stock analysis.'

const SeoProps: DefaultSeoProps = {
    title,
    titleTemplate: '%s',
    description,

    openGraph: {
        title,
        description,
        type: 'website',
        locale: 'en_US',
        // we want to keep this static across all envs, as we don't want to populate SEO with any testing or dev urls
        url: SEO_DOMAIN,
        site_name: 'Finimize',
        images: [
            {
                url: `${SEO_DOMAIN}/images/og-image.png`,
                width: 1200,
                height: 627,
                alt: 'Finimize - Become a smarter investor.',
            },
        ],
    },
    twitter: {
        handle: '@finimize',
        site: '@finimize',
        cardType: 'summary_large_image',
    },
}
export default SeoProps
