import type { ButtonVariant, ButtonProps } from './Button'
import Button from './Button'
import ButtonBase from './ButtonBase'
import InlineButton from './InlineButton'
import type { ButtonProps as LegacyButtonProps } from './LegacyButton'
import LegacyButton from './LegacyButton'

export { Button, ButtonBase, LegacyButton, InlineButton }

export type { ButtonProps, ButtonVariant, LegacyButtonProps }

export default Button
