import classNames from 'classnames'
import styled, { css } from 'styled-components'
import { system } from 'styled-system'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { fonts, layout } from 'theme'
const mobile = layout.breakpoints.small

type LocalHeadingProps = {
    children: React.ReactNode
    isSubHeading?: boolean
    maxLines?: number
}

export type HeadingProps = LocalHeadingProps & BoxProps

export const Heading = ({
    children,
    className,
    maxLines,
    isSubHeading = false,
    as = 'h1',
    color = 'neutral.999',
    fontWeight = fonts.weight.bold,
    lineHeight = 1.2,
    marginBottom = '2rem',
    ...props
}: HeadingProps) => (
    <_Heading
        isSubHeading={isSubHeading}
        as={as}
        maxLines={maxLines}
        color={color}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        marginBottom={marginBottom}
        className={classNames({
            [className || '']: Boolean(className),
            heading: !isSubHeading,
            'sub-heading': isSubHeading,
        })}
        {...props}
    >
        {children}
    </_Heading>
)

const Size = system({
    fontSize: {
        property: 'fontSize',
        scale: 'fonts.size',
        transform: (n: any) => (typeof n === 'number' ? n + 'rem' : n),
    },
})

export default Heading

const _Heading = styled(Box)<HeadingProps>`
    &:last-child {
        margin-bottom: 0;
    }

    h1& {
        font-size: ${fonts.size.XXXL};
        @media all and (max-width: ${mobile}) {
            font-size: ${fonts.size.XXXL};
        }
    }
    h2& {
        font-size: ${fonts.size.XXXL};
        @media all and (max-width: ${mobile}) {
            font-size: ${fonts.size.XXL};
        }
    }
    h3& {
        font-size: ${fonts.size.XXL};
        @media all and (max-width: ${mobile}) {
            font-size: ${fonts.size.XL};
        }
    }
    h4& {
        font-size: ${fonts.size.XL};
    }
    h5& {
        font-size: ${fonts.size.S};
    }
    h6& {
        font-size: ${fonts.size.XXS};
    }

    && {
        ${Size}
        ${(props: any) =>
            props.isSubHeading &&
            css`
                font-weight: ${fonts.weight.normal};
            `}
        ${(props: any) =>
            props.ta &&
            css`
                text-align: ${props.ta};
            `}
    }

    .heading& + .sub-heading {
        margin-top: -1.5rem;
    }

    ${({ maxLines }) =>
        maxLines &&
        css`
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: ${maxLines};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        `}
`
