import { Poppins, Nova_Mono, Inter, Trispace } from 'next/font/google'

import { legacyColors } from 'theme/Colors/legacyColors'
import { defaultPalette } from 'theme/Palettes/default'
import type { ColorName, ThemeProp, ThemedComponent } from 'theme/Types'
import { getColor } from 'theme/Utils/getColor'

type Theme = typeof Theme

export type { ColorName, ThemeProp, Theme, ThemedComponent }
export { getColor }

export const palette = defaultPalette
export const colors = legacyColors

export type FontSizes = typeof fontSizes[keyof typeof fontSizes]

const inter = Inter({
    weight: ['300', '400', '500', '600', '700', '800', '900'],
    subsets: ['latin'],
    display: 'swap',
})

const poppins = Poppins({
    weight: ['400', '500', '600', '700'],
    subsets: ['latin'],
    display: 'swap',
})

export const novaMono = Nova_Mono({
    weight: ['400'],
    subsets: ['latin'],
    display: 'swap',
})

export const trispace = Trispace({
    weight: ['400'],
    subsets: ['latin'],
    display: 'swap',
})

const fontSizes = {
    XXXXXL: '3.5rem',
    XXXXL: '2.8rem',
    XXXL: '2rem',
    XXL: '1.75rem',
    XL: '1.5rem',
    L: '1.25rem',
    M: '1.1875rem',
    S: '1rem',
    XS: '0.875rem',
    XXS: '0.6875rem',
} as const

export const fonts = {
    size: fontSizes,
    weight: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        black: 800,
        extraBold: 900,
    },
    family: {
        body: `${inter.style.fontFamily}, ${poppins.style.fontFamily}, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, ${novaMono.style.fontFamily};`,
        title: `${inter.style.fontFamily}, ${poppins.style.fontFamily}, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, ${novaMono.style.fontFamily};`,
        paragraph: `${inter.style.fontFamily}, ${poppins.style.fontFamily}, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, ${novaMono.style.fontFamily};`,
    },
    lineHeight: {
        heading: 1.25,
        paragraph: 1.4,
    },
} as const

export const elevation = [
    'unset',
    '0px 1px 2px 0px rgba(0, 0, 0, 0.12)',
    '0px -1px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    '0px -1px 8px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.08)',
    '0px -1px 16px rgba(0, 0, 0, 0.08), 0px 2px 16px rgba(0, 0, 0, 0.08)',
]

export const space = [
    0,
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
    '9rem',
    '10rem',
]
export const layout = {
    space: {
        major: 8,
        minor: 4,
        gap: '1rem',
    },
    breakpoints: {
        xSmall: '320px',
        small: '480px',
        medium: '768px',
        large: '1024px',
        xLarge: '1200px',
        xxLarge: '1400px',
        xxxLarge: '1600px',
    },
}
export const radii: { [index: string]: any } = {
    small: '0.25rem',
    default: '0.5rem',
    medium: '0.75rem',
    large: '1rem',
    rounded: '1000rem',
}

const searchFilterZIndex = 9999 //see SearchFilter/Dropdown - to do with use of react select

export const zIndex = {
    layerBase: 0,
    layer01: 10,
    layer02: 20,
    layer03: 30,
    layer04: 40, // ScrollCarousel, Search Results, NavMobile and FreeArticleCountBanner
    layer05: 50, // ShareContentDropdown
    layer06: searchFilterZIndex,
    layer07: searchFilterZIndex + 10, // NavBarTop
    layer08: searchFilterZIndex + 20, // NavBarSide
    layer09: searchFilterZIndex + 30, // Modal Dialog Backdrop
    layerTop: 9999999, // Modals
}

export const borders = {}
export const global = `
    html,
    body {
        box-sizing: border-box;
        font-family: ${fonts.family.body};
        font-size: ${fonts.size.S};
        font-weight: ${fonts.weight.normal};
        line-height: 1.5;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        color: ${palette.neutral[700]};
        fill: ${palette.neutral[700]};
    }

    html, body, #__next {
        height: 100%;
    }

    
    body,
    p,
    ol,
    ul,
    li,
    dl,
    dt,
    dd,
    blockquote,
    figure,
    fieldset,
    legend,
    textarea,
    pre,
    iframe,
    hr,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    margin: 0;
    padding: 0;
    hyphens:none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    font-size: 100%;
    font-weight: ${fonts.weight.normal};
    }

    img,
    video {
        height: auto;
        max-width: 100%;
    }

    html.wf-active,
    html.wf-active > body {
        font-family: ${fonts.family.body};
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    *:focus {
        outline: none;
        outline-offset: 2px;
    }
    input, select {
        font-family: ${fonts.family.body};
        font-size: ${fonts.size.S};
        font-weight: ${fonts.weight.normal};
        line-height: 1.5;
    }
    .clear-space {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
`

export const gradients = {
    light: 'linear-gradient(117deg, rgba(219,243,47,1) -75%, rgba(238,241,248,1) 50%, rgba(32,76,233,1) 175%)',
    strong: 'linear-gradient(149deg, rgba(18,48,108,1) 0%, rgba(89,126,255,1) 75%)',
}

const Theme = {
    colors: legacyColors,
    palette: defaultPalette,
    fonts: fonts.family,
    fontWeights: fonts.weight,
    fontSize: fonts.size,
    lineHeight: fonts.lineHeight,
    space,
    elevation,
    zIndex,
    layout,
    radii,
    borders,
    breakpoints: [
        layout.breakpoints.small,
        layout.breakpoints.medium,
        layout.breakpoints.large,
        layout.breakpoints.xLarge,
        layout.breakpoints.xxLarge,
        layout.breakpoints.xxxLarge,
    ],
    gradients: gradients,
}

export default Theme
