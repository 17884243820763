export * from './stripe'

// this domain is used for SEO purposes, so we want to only link to the prod url
// don't want to index other ones!
export const SEO_DOMAIN = 'https://finimize.com'

export const ImageRatios = {
    GOLDEN_RATIO: 1.61803398,
    SQUARE: 1,
} as const
