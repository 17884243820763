/**
 * WAF returns a 405, and warns that if you're using this status code
 * for other things the logic will clash
 * https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-conditional.html
 * So for now can assume that if we get a 405 it's a WAF captcha
 */
export const wafCaptchaStatusCode = 405

export const wafCaptchaErrorMessage = 'WAF captcha requested'

export const wafChallengeStatusCode = 202;

export const maxWafChallengeRetries = 10;

export const wafChallengeRetryDelayMs = 250;