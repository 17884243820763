import type { HeadingProps } from 'components/Heading'
import Heading from 'components/Heading'
import HighlightText, { applyTextHighlightingStyles } from 'components/HighlightText'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme/index'

export const cardHeadingHoverTrigger = applyTextHighlightingStyles

export type CardHeadingProps = {
    children: string

    maxLines: HeadingProps['maxLines']
    fontSize: HeadingProps['fontSize']
}

const CardHeading = ({ children, maxLines, fontSize }: CardHeadingProps) => (
    <Box ml="-1rem" mb="0.5rem">
        <Heading
            as="h3"
            maxLines={maxLines}
            fontSize={fontSize}
            lineHeight={fonts.lineHeight.paragraph}
            px="0.5rem"
        >
            <HighlightText $highlight="none">{children}</HighlightText>
        </Heading>
    </Box>
)

export default CardHeading
