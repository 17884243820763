// couldn't find this in the new stripe lib, so just typed manually for now
type InvoiceStatus = 'draft' | 'paid' | 'uncollectible' | 'void' | 'open'

type InvoiceStatusMapping = { [key: string]: InvoiceStatus }

export const INVOICE_STATUS: InvoiceStatusMapping = {
    DRAFT: 'draft',
    PAID: 'paid',
    UNCOLLECTIBLE: 'uncollectible',
    VOID: 'void',
    OPEN: 'open',
}
