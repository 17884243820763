import type { IncomingMessage, ServerResponse } from 'http'

import {configureScope as sentryConfigureScope, captureException as sentryCaptureException} from '@sentry/nextjs'
import Cookie from 'js-cookie';
import type { NextPageContext } from 'next';

interface RequestContext {
    params: Record<string, string>;
    cookies: Record<string, string>;
}

type ErrorContext = {
    req?: IncomingMessage & NextPageContext & RequestContext,
    res?: ServerResponse,
    errorInfo?: {[k: string]: any}
    query?: string //? I think 
    pathname?: string //? I think 
}

export const captureException = (err: any, ctx?: ErrorContext) => {
    sentryConfigureScope((scope:any) => {
        if (err.message) {
            // De-duplication currently doesn't work correctly for SSR / browser errors
            // so we force deduplication by error message if it is present
            scope.setFingerprint([err.message])
        }

        if (err['statusCode']) {
            scope.setExtra('statusCode', err['statusCode'])
        }

        if (ctx) {
            const { req, res, errorInfo, query, pathname } = ctx

            if (res && res.statusCode) {
                scope.setExtra('statusCode', res.statusCode)
            }

            if (process['browser']) {
                scope.setTag('ssr', false)
                scope.setExtra('query', query)
                scope.setExtra('pathname', pathname)

                // On client-side we use js-cookie package to fetch it
                const sessionId = Cookie.get('sid')
                if (sessionId) {
                    scope.setUser({ id: sessionId })
                }
            } else {
                scope.setTag('ssr', true)
                scope.setExtra('url', req?.url)
                scope.setExtra('method', req?.method)
                scope.setExtra('headers', req?.headers)
                scope.setExtra('params', req && req['params'])
                scope.setExtra('query', req && req['query'])

                // On server-side we take session cookie directly from request
                if (req && req['cookies'] && req['cookies']['sid']) {
                    scope.setUser({ id: req['cookies']['sid']})
                }
            }

            if (errorInfo) {
                Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]))
            }
        }
    })

    return sentryCaptureException(err)
}