/**
 * `Columns/Column` are for page structure.
 * Use `Set` for smaller layouts
 */
import React from 'react'

import styled, { css } from 'styled-components'

import ColumnsContext from './ColumnsContext'

import Box, { type BoxProps } from 'components/Primitives/Box'
import { layout } from 'theme'

type LocalColumnsProps = {
    children?: React.ReactNode
    isGapless?: boolean
    isMobile?: boolean
}
export type ColumnsProps = LocalColumnsProps & BoxProps

const Columns = ({
    children,
    className,
    isGapless,
    isMobile,

    ...props
}: ColumnsProps) => (
    <ColumnsContext.Provider value={{ isGapless, isMobile }}>
        <_Columns className={className} {...{ isGapless, isMobile }} {...props}>
            {children}
        </_Columns>
    </ColumnsContext.Provider>
)

export const columnsDefaultProps = {
    className: undefined,
    isGapless: false,
    isMobile: false,
}
Columns.defaultProps = columnsDefaultProps

/***
 * STYLES
 **/

export const getWrapProperties = ({ isMobile }: any) => {
    if (!isMobile) {
        return css`
            @media (max-width: ${layout.breakpoints.medium}) {
                display: block;
            }
        `
    }
    return css``
}

const _Columns = styled(Box)<ColumnsProps>`
    display: flex;
    flex-wrap: wrap;
    ${(props: any) => {
        const { gap } = layout.space
        return (
            !props.isGapless &&
            css`
                margin-left: -${gap};
                margin-right: -${gap};
                margin-top: -${gap};
                &:last-child {
                    margin-bottom: -${gap};
                }
            `
        )
    }}
    ${getWrapProperties};
`

export default Columns
