const initialiseWebtrends = () => {
    if (!document.getElementById('webtrends-script')) {
        const script = document.createElement('script')
        script.id = 'webtrends-script'
        script.src =
            '//c.webtrends-optimize.com/acs/accounts/cde0ff8c-91aa-404a-9c17-204acb8c966f/js/wt.js'
        script.async = true
        document.head.appendChild(script)
    }
}

export default initialiseWebtrends
