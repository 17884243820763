import { createContext } from 'react'

export type ColumnsContextProps = {
    isGapless?: boolean
    isMobile?: boolean
}

export default createContext({
    isGapless: false,
    isMobile: false,
} as ColumnsContextProps)
