export type Colors = typeof colors

export const colors = {
    day: {
        900: '#000E1A',
        800: '#002C53',
        700: '#004B8D',
        600: '#0069C6',
        500: '#0087FF',
        400: '#40A5FF',
        300: '#80C3FF',
        200: '#BFE1FF',
        100: '#DFF0FF',
        50: '#F7FBFF',
    },
    night: {
        900: '#02050B',
        800: '#061023',
        700: '#0A1B3C',
        600: '#0E2554',
        500: '#12306C',
        400: '#4D6491',
        300: '#8998B6',
        200: '#C4CBDA',
        100: '#E2E5ED',
        50: '#F8F9FB',
    },
    azure: {
        900: '#13192B',
        800: '#0A194C',
        700: '#122A80',
        600: '#193BB5',
        500: '#204CE9',
        400: '#5879EF',
        300: '#90A6FA',
        200: '#C7D2FA',
        100: '#E3E9FD',
        50: '#F8FAFF',
    },
    concrete: {
        999: '#030303',
        900: '#181c25',
        800: '#262a37',
        700: '#383F4F',
        600: '#4C5469',
        500: '#5E6984',
        400: '#7F879C',
        300: '#CBCFDA',
        200: '#E4E8F3',
        100: '#eef1f8',
        50: '#f8f9ff',
        0: '#FFFFFF',
    },
    lavender: {
        900: '#02010C',
        800: '#070227',
        700: '#0C0342',
        600: '#10045D',
        500: '#150578',
        400: '#50449A',
        300: '#8A82BC',
        200: '#C5C1DD',
        100: '#E2E0EE',
        50: '#F8F7FB',
    },
    green: {
        900: '#062C1A',
        800: '#0B5833',
        700: '#11854D',
        600: '#149b5a',
        500: '#16B166',
        400: '#55E6A0',
        300: '#8EEEC0',
        200: '#C6F7DF',
        100: '#E3FBEF',
        50: '#FAFDFB',
    },
    yellow: {
        900: '#171106',
        800: '#4B3713',
        700: '#7E5D20',
        600: '#B2822C',
        500: '#E5A839',
        400: '#ECBE6B',
        300: '#F2D49C',
        200: '#F9E9CE',
        100: '#FCF4E7',
        50: '#FEFCF9',
    },
    starship: {
        900: '#2D2E06',
        800: '#3B3C1A',
        700: '#747933',
        600: '#AEB54F',
        500: '#E6F14A',
        400: '#F0F964',
        300: '#ECF482',
        200: '#F1F79E',
        100: '#F6FABE',
        50: '#FBFCDF',
    },
    red: {
        900: '#170509',
        800: '#4C111D',
        700: '#811D31',
        600: '#B52845',
        500: '#EA3459',
        400: '#EF6783',
        300: '#F59AAC',
        200: '#FACCD6',
        100: '#FDE6EB',
        50: '#FFF9FA',
    },
}
