import type { Environment } from 'relay-runtime'

import type { CheckoutDataParams } from './analytics/helpers'
import { getAttributionParams, setAttributionParams } from './analytics/helpers'
import analyticsClient from './analyticsClient'
import {
    trackCompleteRegistration,
    trackSubscribe as trackFBSubscribe,
    trackStartTrial as trackFBTrial,
    trackPageView as trackFBPageview,
    trackAppStoreClick as trackFBAppStoreClick,
    trackSearch as trackFBSearch,
    trackNewsletterEmailSubmitted as trackFBNewsletterEmailSubmitted,
    trackNewsletterSignupCompleted as trackFBNewsletterSignupCompleted,
} from './tracking/fb/fbTrackingApi'
import type { AppStoreClickProps } from './tracking/fb/types'
import type { StartTrial, Subscribe } from './tracking/fmzTrackingClient'
import { trackSignup as trackFMZSignup } from './tracking/fmzTrackingClient'

import type { LayoutComponentTrackingProps } from 'components/LayoutComponents/types'
import type { NewsletterEdition } from 'data'
import type { SocialShareContentVariants } from 'modules/ShareContent/consts'

export * from './analytics/helpers'

const LOGIN_METHOD = 'Login method'
const CAMPAIGN_ID = 'Campaign ID'
const STRIPE_NICKNAME = 'Stripe nickname'
const LOGIN_METHOD_CHECKOUT = 'Checkout'

const NEWSLETTER_EMAIL_SUBMITTED = 'Newsletter email submitted'
const NEWSLETTER_SIGNUP_COMPLETED = 'Newsletter signup completed'

/*
    This file contains all the tracking events that are sent to mixpanel.

    Read some Mixpanel tips and best practices in this doc:
    https://www.notion.so/finimize/Mixpanel-Tracking-Best-Practices-12d5518c481a47e3bb847e49fb89a6c5
*/

/*
    --------------
    AUTHENTICATION
    --------------
*/

// This will attach the user's email to any mixpanel tracking events (as per website terms)
export function identifyUser(email: string, wasCreated: boolean | null) {
    const lcEmail = email.trim().toLowerCase()

    if (wasCreated) {
        // create new mixpanel user if signing up
        analyticsClient.alias(lcEmail)
        analyticsClient.set({ $email: lcEmail })
    } else {
        // link to existing mixpanel user if logging in
        analyticsClient.identify(lcEmail)
    }
}

type AuthMethod = 'apple' | 'email' | 'google'

/*
    Signup events
*/

export function trackSignUpPressed(method: AuthMethod, message: string) {
    analyticsClient.trackWithProperties('Signup Pressed', {
        method,
        message,
    })
}

export function trackSignUpFailed(method: AuthMethod, message: string) {
    analyticsClient.trackWithProperties('Signup Failed', {
        method,
        message,
    })
}

export function trackSignUpCompleted(
    method: AuthMethod,
    refPage: string | undefined,
    email: string,
    ctaSource: string | undefined,
) {
    analyticsClient.trackWithProperties('Signup Completed', {
        method,
        refPage,
        email,
        ctaSource,
    })
    trackCompleteRegistration({ content_name: refPage })
    identifyUser(email, true)
    trackFMZSignup({ email })
}

export function trackSignupOnCheckout(stripeNickname: string, email?: string, slug?: string) {
    analyticsClient.trackWithProperties('Sign up On Checkout Web', {
        [LOGIN_METHOD]: LOGIN_METHOD_CHECKOUT,
        [CAMPAIGN_ID]: slug,
        [STRIPE_NICKNAME]: stripeNickname,
        email,
    })

    if (!email) return

    const lcEmail = email.trim().toLowerCase()

    analyticsClient.alias(lcEmail)
    analyticsClient.set({ $email: lcEmail })
}

/*
    Login events
*/

export function trackLoginPressed(method: AuthMethod, message: string) {
    analyticsClient.trackWithProperties('Login Pressed', {
        method,
        message,
    })
}

export function trackLoginFailed(method: AuthMethod, message: string) {
    analyticsClient.trackWithProperties('Login Failed', {
        method,
        message,
    })
}

export function trackLoginCompleted(method: AuthMethod, email: string) {
    analyticsClient.trackWithProperties('Login Completed', {
        method,
        email,
    })
    identifyUser(email, false)
}

/*
    Logout events
*/

export const trackLogOut = () => {
    analyticsClient.track('Log Out web')
    analyticsClient.reset()
}

/*
    Other auth-related events
*/

export function trackPasswordResetSent() {
    analyticsClient.track('Password Reset Sent')
}

export function trackWafGetTokenFailed(mutationType: 'SignUp' | 'Login') {
    analyticsClient.trackWithProperties('WAF Get Token Failed', {
        'Mutation type': mutationType,
    })
}

/*
    ------------------------
    SUBSCRIPTIONS AND TRIALS
    ------------------------
*/

type PurchaseProps = {
    amount: number
    currency?: string
    slug?: string
    discount?: number
}

/*
    Subscription events
*/

export function trackClickManageSubscription() {
    analyticsClient.track('Click manage subscription')
}

export function attemptCancellation() {
    analyticsClient.track('Click Cancel Subscription Web')
}

export const trackSubscription = (
    environment: Environment,
    fbc: string,
    fbp: string,
    customerId: string | null,
    { amount, currency }: PurchaseProps & Omit<Subscribe, 'value'>,
) => {
    trackFBSubscribe(environment, {
        fbc,
        fbp,
        currency,
        eventId: customerId,
        value: amount,
        // TODO: Check if we want to re-add these
        // as custom parameters once we add support
        // slug,
        // discount
    })
}

export const trackSubscriptionProxy = (props: PurchaseProps & CheckoutDataParams) => {
    analyticsClient.trackWithProperties('Purchase subscription proxy Web', props)
}

export function redeemCorporateCode(companyName: string, isDiscountCode = false) {
    analyticsClient.trackWithProperties('Corporate redeem Code', { name: companyName })
    analyticsClient.set({
        'Redeemed corporate Code': true,
        'Redeemed code company name': companyName,
        'Is discount code': isDiscountCode,
    })
}

/*
    Trial events
*/

export const trackStartTrial = (
    environment: Environment,
    fbc: string,
    fbp: string,
    customerId: string | null,
    { amount, currency }: PurchaseProps & Omit<StartTrial, 'value'>,
) => {
    trackFBTrial(environment, {
        currency,
        value: amount,
        fbp,
        fbc,
        eventId: customerId,
        // TODO: can re-add as part of custom events and parameters
        // slug,
        // discount
    })
}

// Proxy events that we can use on the client so we can pass more information
// useful for conversion data etc from cookie
export const trackStartTrialProxy = (props: PurchaseProps & CheckoutDataParams) => {
    analyticsClient.trackWithProperties('Trial started proxy Web', props)
}

/*
    -------
    CONTENT
    -------

    Make sure you are using globalContentPieceId as id so that the event can be properly mapped to content pieces.
    Using just 'id' will produce a different value when the user is logged in.
*/
export type ContentTrackingProps = {
    id: string
    type: string
    walled: boolean
    title: string | null
    parentTitle: string | null
    parentId: string | null
    chapters: boolean
    text: boolean
    audio: boolean
    isFreePreview: boolean
}

export type TrackLicenseContentProps = { contentPieceId: string; contentPieceTitle: string }

export type SocialShareContentProps = {
    contentPieceId: string
    contentPieceTitle: string
    network: SocialShareContentVariants
    location: 'top' | 'bottom'
}

interface ToggleBookmarkProps {
    contentPieceId: string
    contentPieceName: string
    toggledVia: string
}

/*
    Content consumption events
*/

// This is used to set the last piece of content the user has started at the time
// of an event being fired as a super property, so that we can analyse
// how content users read influences their behaviour, e.g. what content type
// best leads to a trial/subscription

// Make sure that you're using `globalContentPieceId` as the id that is passed through
// https://www.notion.so/finimize/Mixpanel-Tracking-Best-Practices-12d5518c481a47e3bb847e49fb89a6c5?pvs=4#d415bccf552c49efb392f2336a7717cb
export const trackLastStartedContent = (contentPieceId: string) => {
    analyticsClient.register({ 'Last started content piece ID': contentPieceId })
}

export const trackStartReadContent = (props: ContentTrackingProps) => {
    analyticsClient.trackWithProperties('Start Read Content', props)
}

export const trackEndReadContent = (props: ContentTrackingProps) => {
    analyticsClient.trackWithProperties('End Read Content', props)
}

export const trackContentPieceRated = (
    trackingParams: ContentTrackingProps,
    rating: number,
): void => {
    analyticsClient.trackWithProperties('Rate Content Piece', {
        ...trackingParams,
        rating,
    })
}

export const trackAttemptListenContent = (props: Omit<ContentTrackingProps, 'isFreePreview'>) => {
    analyticsClient.trackWithProperties('Attempt Listen Content Web', props)
}

export const trackViewPaywalledContent = (props: ContentTrackingProps) => {
    analyticsClient.trackWithProperties('View Paywalled Content', props)
}

export const trackClickRelatedContent = (props: { id: string }) => {
    analyticsClient.trackWithProperties('Clicked Related Content', props)
}

type CardLocation = 'most_popular'
export const trackClickContentCard = (props: { id: string; location: CardLocation }) => {
    analyticsClient.trackWithProperties('Clicked Content Card', props)
}

export const trackViewFreeAllowanceBanner = (props: { number: number }) => {
    analyticsClient.trackWithProperties('Viewed Free Allowance Banner', { props })
}

export const trackClickFreeAllowanceBanner = (props: { number: number; expanded: boolean }) => {
    analyticsClient.trackWithProperties('Clicked Free Allowance Banner', { props })
}

export const trackToggleFreeAllowanceBanner = (props: { number: number; expanded: boolean }) => {
    analyticsClient.trackWithProperties('Toggled Free Allowance Banner', { props })
}

/*
    Content sharing events
*/

export const trackDownloadContent = (props: { id: string; title: string }) => {
    analyticsClient.trackWithProperties('Download Content B2B', props)
}

export const trackLicenseContent = (props: TrackLicenseContentProps) => {
    analyticsClient.trackWithProperties('Clicked License Button', props)
}

export const trackContentPieceLinkCopied = (props: {
    contentPieceId: string
    contentPieceTitle: string
}) => {
    analyticsClient.trackWithProperties('Content piece link copied', props)
}

export const trackSocialShareContent = (props: SocialShareContentProps) => {
    analyticsClient.trackWithProperties('Social share content', props)
}

/*
    Bookmark events
 */

export const trackToggledBookmarkOn = ({
    contentPieceId,
    contentPieceName,
    toggledVia,
}: ToggleBookmarkProps) => {
    analyticsClient.trackWithProperties('Toggled Bookmark On', {
        'Bookmark Type': 'Content Piece',
        'Content Piece ID': contentPieceId,
        'Content Piece Name': contentPieceName,
        'Toggled Via': toggledVia,
    })
}

export const trackToggledBookmarkOff = ({
    contentPieceId,
    contentPieceName,
    toggledVia,
}: ToggleBookmarkProps) => {
    analyticsClient.trackWithProperties('Toggled Bookmark Off', {
        'Bookmark Type': 'Content Piece',
        'Content Piece ID': contentPieceId,
        'Content Piece Name': contentPieceName,
        'Toggled Via': toggledVia,
    })
}

/*
    -------
    ONBOARDING
    -------
*/
// TODO: Mixpanel Tracking coming in follow up PR
type TrackSkippedOnboardingProps = {
    step: string
    sourceChannel: string
}
export const trackSkippedOnboardingStep = ({
    step,
    sourceChannel,
}: TrackSkippedOnboardingProps) => {
    analyticsClient.trackWithProperties('Onboarding Form - Skipped Step', {
        step,
        sourceChannel,
    })
}

type TrackSubmitOnboardingProps = {
    sourceChannel: string
}
// not sure if want to submit with selections or just that they submitted
export const trackSubmitOnboarding = ({ sourceChannel }: TrackSubmitOnboardingProps) => {
    analyticsClient.trackWithProperties('Onboarding Form - Submitted', {
        sourceChannel,
    })
}

/*
    ----------
    NEWSLETTER
    ----------
 */

// On the initial form submission or when we get their email
export const trackNewsletterEmailSubmitted = ({
    email,
    isPremium = false,
}: {
    email: string
    isPremium?: boolean
}) => {
    analyticsClient.trackWithProperties(NEWSLETTER_EMAIL_SUBMITTED, { email, isPremium })
    trackFBNewsletterEmailSubmitted()
}

// When user completes entire flow
export const trackNewsletterSignupCompleted = (
    environment: Environment,
    fbc: string,
    fbp: string,
    {
        email,
        edition,
        isPremium = false,
    }: {
        email: string
        edition?: NewsletterEdition
        isPremium?: boolean
    },
) => {
    analyticsClient.trackWithProperties(NEWSLETTER_SIGNUP_COMPLETED, { email, edition, isPremium })
    trackFBNewsletterSignupCompleted(environment, { fbc, fbp, eventId: null })
}

/*
    -----------
    EXPERIMENTS
    -----------
 */

type ExperimentProps = {
    experimentName: string
    usedVariantName: string
}

/*
    registerExperiment
    
    Used in the useEnrollInExperiment hook to send the experiments data to mixpanel as 
    super properties (properties that get sent with every event in the session).
*/
export const registerExperiment = ({ experimentName, usedVariantName }: ExperimentProps) => {
    const experimentProperty = { [`Experiment ${experimentName}`]: usedVariantName }
    analyticsClient.register(experimentProperty)
}

/*
    trackExperimentStarted

    This fires when an experiment has started for a user. It's used in the useEnrollInExperiment hook.
    Using the $experiment_started event name and these exact property names allows Mixpanel to register 
    it in the Experiments app:
    https://mixpanel.com/project/1527361/view/118275/app/experiment-reporting
*/
export const trackExperimentStarted = ({ experimentName, usedVariantName }: ExperimentProps) => {
    analyticsClient.trackWithProperties('$experiment_started', {
        'Experiment name': experimentName,
        'Variant name': usedVariantName,
    })
}

/*
    ------
    CONVERSION FUNNEL
    ------
*/

export enum CTAPlacement {
    ALWAYS_ON_PAGE_BANNER = 'always_on_page_banner',
    NAV_BAR = 'nav_bar',
    CONTENT_BANNER_PAYWALL = 'content_banner_paywall',
    MODAL_PAYWALL = 'modal_paywall',
}

export type ConversionCTAParams = {
    placement: CTAPlacement
}

export const trackConversionCTACLicked = (params: ConversionCTAParams) => {
    analyticsClient.trackWithProperties('Conversion CTA - clicked', params)
}

export const trackPageCTABannerClicked = () => {
    analyticsClient.track('Clicked page CTA banner')
    trackConversionCTACLicked({ placement: CTAPlacement.ALWAYS_ON_PAGE_BANNER })
}

export type NotLoggedInModalConversionTrackingProps = {
    location: string
}

export const trackNotLoggedInModalConversion = (
    params: NotLoggedInModalConversionTrackingProps,
) => {
    analyticsClient.trackWithProperties('Not logged in modal conversion', params)
    trackConversionCTACLicked({ placement: CTAPlacement.MODAL_PAYWALL })
}

/*
    -------
    PAYWALL
    -------
*/

export enum PaywallFlowPointEnum {
    FREE_UNLOCK = 'Banner unlock free',
    FREE_DOWNGRADE = 'Banner downgrade free',
    PREMIUM_UNLOCK = 'Banner unlock premium',
    PREMIUM_UPGRADE = 'Banner upgrade premium',
}

export enum PlansPricingFlowPointEnum {
    FREE = 'Pricing plan free',
    PREMIUM = 'Pricing plan premium',
}

export const trackClickedPaywallBanner = (flowPoint?: PaywallFlowPointEnum) => {
    if (flowPoint) {
        analyticsClient.trackWithProperties('PaywallBanner clicked', {
            flowPoint,
        })
    } else {
        analyticsClient.track('PaywallBanner clicked')
    }
    trackConversionCTACLicked({ placement: CTAPlacement.CONTENT_BANNER_PAYWALL })
}

export const trackClickedPlansPricingBanner = (flowPoint?: PlansPricingFlowPointEnum) => {
    if (flowPoint) {
        analyticsClient.trackWithProperties('PlansPricing clicked', {
            flowPoint,
        })
    } else {
        analyticsClient.track('PlansPricing clicked')
    }
    trackConversionCTACLicked({ placement: CTAPlacement.CONTENT_BANNER_PAYWALL })
}

// Temporary explicit tracking for paywall experiment
type PaywallVariant = 'paywall_default' | 'paywall_new'
export const trackViewedPaywall = (variant: PaywallVariant) => {
    analyticsClient.trackWithProperties('Viewed Paywall (temp)', { variant })
}

export const trackClickedSubmitPaywallSignUp = (variant: PaywallVariant) => {
    analyticsClient.trackWithProperties('Clicked Paywall Sign Up (temp)', { variant })
}

export const trackClickedPaywallSubscribe = (variant: PaywallVariant) => {
    analyticsClient.trackWithProperties('Clicked Paywall Subscribe (temp)', { variant })
}

/*
    ------
    SEARCH
    ------
*/

export const trackSearch = (
    environment: Environment,
    isAuthenticated: boolean,
    fbc: string,
    fbp: string,
    searchString: string,
) => {
    if (!searchString || searchString.length < 3) return
    if (searchString) {
        analyticsClient.trackWithProperties('Search term triggered', { searchString })

        if (!isAuthenticated) {
            return
        }

        trackFBSearch(environment, {
            fbc,
            fbp,
            searchString,
            eventId: null,
        })
    }
}

/*
    ----
    TAGS
    ----
*/

export enum TagPlacementEnum {
    ARTICLE_FOOTER = 'Article footer',
    PAGE_SIDEBAR = 'Page sidebar',
    SUB_NAVIGATION = 'Sub navigation bar',
}

type TagsTrackingProps = {
    placement: TagPlacementEnum
    location: string
    tagName: string
}

export const trackTagClicked = (props: TagsTrackingProps) => {
    analyticsClient.trackWithProperties('Tag clicked', props)
}

export const trackNotFoundRedirect = (props: { origin: string }) => {
    analyticsClient.trackWithProperties('Permanent Redirect', props)
}

/*
    -----------------
    LAYOUT COMPONENTS
    -----------------
*/

export const trackLayoutComponentShown = ({
    layoutComponentType,
    trackingId,
    cta,
}: LayoutComponentTrackingProps) => {
    analyticsClient.trackWithProperties('Layout Component Shown', {
        'Component Type': layoutComponentType,
        'Tracking ID': trackingId,
        'CTA Text': cta?.text ?? null,
        'CTA URL': cta?.url ?? null,
    })
}

export const trackLayoutComponentCTAClicked = ({
    layoutComponentType,
    trackingId,
    cta,
}: LayoutComponentTrackingProps) => {
    analyticsClient.trackWithProperties('Layout Component CTA Clicked', {
        'Component Type': layoutComponentType,
        'Tracking ID': trackingId,
        'CTA Text': cta?.text ?? null,
        'CTA URL': cta?.url ?? null,
    })
}

/*
    ---------------
    PRODUCT ADVERTS
    ---------------
*/

export type ProductAdvertEvent = 'Page Load' | 'Impression' | 'Click'

export type AdvertTrackingProps = {
    advertHandle: string
    advertId: string
    pathname: string
    companyName: string
}

export const trackProductAdvert = (event: ProductAdvertEvent, params: AdvertTrackingProps) => {
    analyticsClient.trackWithProperties(`Product Advert ${event}`, params)
}

/*
    ---------------
    Markets
    ---------------
*/

export type AssetClickedTrackingProps = {
    assetName: string | null
    assetSymbol: string
    contentPieceTitle?: string
}

export const trackAssetClicked = (params: AssetClickedTrackingProps) => {
    analyticsClient.trackWithProperties('Asset clicked', params)
}

export enum AssetDataTrackingName {
    FORWARD_PE = 'forwardPE',
    OPERATING_MARGIN = 'operatingMargin',
    SALES_GROWTH_5_YEAR = 'salesGrowth5Year',
    RETURN_ON_INVESTED_CAPITAL = 'returnOnInvestedCapital',
    ANALYST_RATING = 'analystRating',
    ENTERPRISE_VALUE_TO_SALES = 'enterpriseValueToSales',
    FREE_CASH_FLOW_YIELD = 'freeCashFlowYield',
    AVERAGE_ANALYST_RATING = 'averageAnalystRating',
    NET_INSIDER_BUY_SELL = 'netInsiderBuyingSelling',
    TREND = 'trend',
    STOCKS_BETA = 'stocksBeta',
    NET_DEBT_EBITDA = 'netDebtEBITDA',
    STOCKS_VOLITILITY = 'stocksVolitility',
}

export type AssetHiddenDataClickedTrackingProps = {
    dataName: AssetDataTrackingName
}

export const trackAssetHiddenDataClick = (params: AssetHiddenDataClickedTrackingProps) => {
    analyticsClient.trackWithProperties('Asset hidden data clicked', params)
}

/*
    ---------------
    Navigation Bar
    ---------------
*/

export const trackNavigationItemClicked = (item: string) => {
    analyticsClient.trackWithProperties('Navigation item clicked', { item })
}

/*
    ---------------
    PROFILE
    ---------------
*/

export const trackProfileOnboardingClicked = ({
    hasPreviouslyCompletedOnboardingFlow,
}: {
    hasPreviouslyCompletedOnboardingFlow: boolean
}) => {
    analyticsClient.trackWithProperties('Profile onboarding update clicked', {
        'Has Previously completed onboarding flow': hasPreviouslyCompletedOnboardingFlow,
    })
}

/*
    -------------
    MISCELLANEOUS
    -------------
 */

export const trackPageView = () => {
    const attributionParams = getAttributionParams()
    setAttributionParams()

    analyticsClient.setOnce(attributionParams.firstTouch)
    analyticsClient.register(attributionParams.lastTouch, 7)
    analyticsClient.set(attributionParams.lastTouch)
    analyticsClient.track('Page View')

    trackFBPageview()
}

export const trackErrorPageSeen = () => {
    // Hard 404s can already be tracked by filtering Page View for '/404'
    analyticsClient.track('Error Page Seen (soft 404)')
}

export const trackAppStoreClick = (props: AppStoreClickProps) => {
    trackFBAppStoreClick(props)
    analyticsClient.trackWithProperties('App Store Click', props)
}

export const trackCookieModalSeen = () => {
    analyticsClient.track('View Cookie Modal')
}

export enum COOKIE_MODAL_ACTIONS {
    ALLOW = 'allow',
    DECLINE = 'decline',
}

export const trackCookieModalAction = (action: COOKIE_MODAL_ACTIONS) => {
    analyticsClient.trackWithProperties('Cookie Modal Action', { action })
}
