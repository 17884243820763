import type { IncomingMessage } from 'http'

import cookie from 'cookie'

import { DAY_IN_SECONDS } from './useCookies'

export const TOKEN_KEY = '__finimize_laga_token__'

export function setAuthToken(token: string) {
    document.cookie = cookie.serialize(TOKEN_KEY, token, {
        maxAge: 30 * DAY_IN_SECONDS, // 30 days
        path: '/',
    })
}

export function removeAuthToken() {
    document.cookie = cookie.serialize(TOKEN_KEY, '', {
        expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
        path: '/',
    })
}

export function parseCookies(req?: IncomingMessage, options: any = {}) {
    const parsedCookie = cookie.parse(req ? req.headers.cookie || '' : document.cookie, options)
    return parsedCookie
}

export function getLoginToken(req?: IncomingMessage) {
    return parseCookies(req)[TOKEN_KEY]
}

export function getAuthHeaderFromCookie(req: IncomingMessage): string | undefined {
    const token = getLoginToken(req)

    return token ? `Token ${token}` : undefined
}

function getCookie(name: string) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

export function getCsrfToken() {
    return process && process['browser'] ? getCookie('csrftoken') : undefined
}
