import type { LinkProps } from 'next/link'
import { darken } from 'polished'
import styled, { css } from 'styled-components'
import { switchProp } from 'styled-tools'

import type { SizeProps } from '../types/props'

import { LinkWrapper } from 'components/Link/LinkWrapper'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName } from 'theme'
import { fonts, radii, getColor } from 'theme'

type LocalButtonProps = {
    children: React.ReactNode
    size?: SizeProps
    isBlock?: boolean
    isLoading?: boolean
    isOutlined?: boolean
    isLink?: boolean
    type?: string
    disabled?: boolean
    loadingText?: string
    disabledText?: string
    url?: LinkProps['href']
    target?: string
    params?: { [key: string]: any }
}

export type ButtonProps = LocalButtonProps & BoxProps

export const Button = ({
    children,
    as = 'button',
    className,
    color = 'primary',
    isLoading,
    size = 'medium',
    type,
    params,
    url,
    disabled,
    loadingText = 'Loading...',
    disabledText,
    target,
    isBlock,
    ...props
}: ButtonProps) => {
    return (
        <LinkWrapper url={url}>
            <_Button
                className={className}
                color={color}
                type={type}
                disabled={disabled}
                params={params}
                as={as}
                target={target}
                $size={size}
                $isBlock={isBlock}
                {...props}
            >
                {isLoading ? loadingText : disabled ? disabledText || children : children}
            </_Button>
        </LinkWrapper>
    )
}

export default Button

export const isInteractive = (props: any) => !props.isLoading && !props.disabled && !props.isLink
export const _getColor = ({ color }: any) => getColor(color) || ''
export const _getColorInverted = ({ color }: any) => getColor((color + 'Inverted') as ColorName)

const getOutlineProperties = css`
    background-color: unset;
    border: 2px solid ${_getColor};
    font-weight: ${fonts.weight.bold};
    color: ${_getColor};
    fill: ${_getColor};

    ${props =>
        isInteractive(props) &&
        css`
            &:hover {
                background-color: ${_getColor};
                color: ${_getColorInverted};
                fill: ${_getColorInverted};
            }
        `}
`

const getLinkProperties = (props: any) => {
    const color: string = _getColor(props)
    return css`
        border: 0;
        background: unset;
        color: ${_getColor};
        fill: ${_getColor};
        text-decoration: none;
        padding: 0;
        min-height: inherit;

        &:hover {
            color: ${darken(0.1, color)};
            fill: ${darken(0.1, color)};
            text-decoration: ${!props.disabled && 'underline'};
        }
    `
}

const getInteractiveProperties = (props: any) => {
    const color = _getColor(props)
    return css`
        &:hover {
            background-color: ${darken(0.05, color)};
        }
        &:hover:active {
            background-color: ${darken(0.1, color)};
        }
    `
}

type StyledProps = Omit<ButtonProps, 'size' | 'isBlock'> & { $size: SizeProps; $isBlock: boolean }

const _Button = styled(Box)<StyledProps>`
    min-width: 2rem;
    align-items: center;
    display: ${props => props.display || 'inline-flex'};
    justify-content: center;
    text-decoration: none;
    hyphens: auto;
    font-weight: ${fonts.weight.medium};
    letter-spacing: 0.5px;
    && {
        color: ${_getColorInverted};
        background-color: ${_getColor};
    }
    border: none;
    cursor: pointer;
    font-family: ${fonts.family.body};

    &:focus {
        outline: 0.1rem solid ${_getColor};
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.7;
        outline: unset;
        pointer-events: unset;
    }

    ${switchProp('$size', {
        small: css`
            & {
                font-size: 14px;
                padding: 4px 12px;
                border-radius: ${radii.default};
                font-weight: ${fonts.weight.semibold};
                letter-spacing: 1%;
            }
        `,
        medium: css`
            & {
                font-size: ${fonts.size.S};
                font-weight: ${fonts.weight.bold};
                padding: 0.75rem 1.5rem;
                border-radius: ${radii.default};
                min-height: 2.5rem;
            }
        `,
        large: css`
            & {
                font-size: 1.25rem;
                font-weight: ${fonts.weight.semibold};
                border-radius: ${radii.default};
                padding: 0.8rem 2rem;
            }
        `,
    })}
    && {
        ${props =>
            props.$isBlock &&
            css`
                width: 100%;
            `}

        ${props => isInteractive(props) && getInteractiveProperties}
        ${(props: any) => props.isOutlined && getOutlineProperties}
        ${(props: any) => props.isLink && getLinkProperties}

        /* Hack to turn off underline from <Link /> composition.  */
        &:hover {
            text-decoration: none;
        }
    }
`
