/**
 * @deprecated The colors should not be used moving forward
 */
export type LegacyColors = keyof typeof legacyColors

/**
 * @deprecated The colors should not be used moving forward
 */
export const legacyColors = {
    white: '#fff',
    black: '#171923',
    blackInverted: '#fff',
    black800: '#102440',
    black900: '#091525',
    text: '#3C4857',
    primary: '#0087ff',
    primaryInverted: '#fff',
    primaryDark: '#2F80ED',
    primaryMid: '#83C9FF',
    primaryDarkInverted: '#fff',
    primaryLight: '#C8E7FF',
    danger: '#FD2F60',
    dangerInverted: '#fff',
    warning: '#ffbb2d',
    warningInverted: '#171e27',
    navy: '#4669F6',
    navyInverted: '#fff',
    yellow: '#ffdb2d',
    yellowInverted: '#171e27',
    yellow500: '#FFBC31',
    success: '#0FD165',
    successInverted: '#fff',
    grey1: '#F9FAFC',
    grey2: '#F0F2F7',
    grey3: '#E5E9F2',
    grey4: '#C0CCDA',
    grey5: '#8392A6',
    inputBorder: 'rgba(60, 72, 87, 0.04)',
    transparent: 'transparent',
    modalBackground: 'rgba(0, 0, 0, 0.5)',
    blue100: '#F6FBFF',
    blue200: '#EBF6FF',
    blue300: '#D8EEFE',
    blue400: '#9DD4FD',
    blue500: '#3BA9FC',
    blue600: '#2B7BB8',
    blue700: '#275789',
    blue800: '#102440',
    blue900: '#040D14',
    blueHeader: '#1A3458',
    blueFooter: '#CCE6FE',

    // cross platform colors

    grey200: '#F7FAFC',
    grey300: '#EDF2F7',
    grey400: '#E2E8F0',
    grey600: '#CBD5E0',
    grey700: '#A0AEC0',
    grey800: '#718096',
    grey900: '#4A5568',

    black300: '#2D3748',
    black400: '#1A202C',
    black500: '#171923',

    // generateColorVar
}
