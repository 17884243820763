import React from 'react'

import styled, { css } from 'styled-components'
import { style } from 'styled-system'

import type { ColumnsContextProps } from '../Columns/ColumnsContext'
import ColumnsContext from '../Columns/ColumnsContext'
import type { ColumnSpreadProps } from '../types/props'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { layout } from 'theme'

type LocalColumnProps = {
    children?: React.ReactNode
    spread?: ColumnSpreadProps
    offset?: ColumnSpreadProps
}

export type ColumnProps = LocalColumnProps &
    ColumnsContextProps &
    Omit<
        BoxProps,
        | 'width'
        | 'margin-left'
        | 'ml'
        // The padding props are used internally to create responsive spacing,
        // so we don't want to be able to pass them in
        | 'padding'
        | 'padding-left'
        | 'padding-top'
        | 'padding-bottom'
        | 'padding-right'
        | 'paddingX'
        | 'paddingY'
        | 'p'
        | 'px'
        | 'py'
        | 'pt'
        | 'pr'
        | 'pb'
        | 'pl'
    >

export const Column = ({ children, className, spread, offset, ...props }: ColumnProps) => (
    <ColumnsContext.Consumer>
        {({ isGapless, isMobile }) => (
            <_Column
                className={className}
                spread={spread}
                offset={offset}
                isGapless={isGapless}
                isMobile={isMobile}
                {...props}
            >
                {children}
            </_Column>
        )}
    </ColumnsContext.Consumer>
)

export default Column

const Offset = style({
    prop: 'offset',
    cssProperty: 'margin-left',
    transformValue: (n: number) => {
        return (n / 12) * 100 + '%'
    },
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
})

const Spread = style({
    prop: 'spread',
    cssProperty: 'width',
    transformValue: (n: number) => (n / 12) * 100 + '%',
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
})

export const _Column = styled(Box)<ColumnProps>`
    max-width: ${props => props.maxWidth || '100%'};
    box-sizing: border-box;
    flex: ${props => (props.spread ? 'none' : 1)};
    ${props =>
        !props.isGapless &&
        css`
            padding: ${layout.space.gap};
        `}
    ${Offset}
    ${Spread}
    & {
        ${props =>
            !props.isMobile &&
            css`
                @media (max-width: ${layout.breakpoints.medium}) {
                    width: 100%;
                    margin: 0;
                }
            `}
    }
`
