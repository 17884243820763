import type { LinkProps } from './Link';
import Link from './Link'

type TextLinkProps = Omit<LinkProps, 'as'>

const TextLink = ({ children, ...textLinkProps }: TextLinkProps) => (
    <Link as="span" {...textLinkProps}>
        {children}
    </Link>
)

export default TextLink
