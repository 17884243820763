import type { LegacyColors } from '../Colors/legacyColors'
import { legacyColors } from '../Colors/legacyColors'
import { defaultPalette } from '../Palettes/default'
import type { ColorName, PaletteColorKey } from '../Types'

type RGB = `rgb(${number}, ${number}, ${number})`
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`
type HEX = `#${string}`

/**
 * @deprecated This is purely for backwards compatibility, don't use.
 */
type ColorCode = RGB | RGBA | HEX

type ColorType = ColorName | ColorCode

function isPaletteColor(colorName: ColorType): colorName is PaletteColorKey {
    return colorName.includes('.')
}

export const getColor = (colorName?: ColorType): string | null => {
    // some guarding - allows us to cleanly pass undefined colorNames into the function
    if (colorName == null) return null
    if (isPaletteColor(colorName)) {
        // split the string to get the colour and shade.
        const [color, shade] = colorName.split('.')

        // Safe to force the typing in this way due to the safety around from the other typing.
        // Without this typescript can't infer the type properly.
        // More verbose, but more explicit than an "ignore typing" comment
        const _colors = defaultPalette as { [color: string]: { [shade: string]: string } }

        return _colors[color][shade]
    }

    return legacyColors[colorName as LegacyColors] ?? colorName
}
