import type { IconBaseProps } from 'react-icons'
import {
    RiCalendar2Line,
    RiCheckboxBlankCircleLine,
    RiArrowLeftLine,
    RiArrowRightLine,
    RiArrowRightSLine,
    RiArrowDownSLine,
    RiArticleLine,
    RiBookOpenLine,
    RiStarFill,
    RiAppleFill,
    RiAndroidFill,
    RiCheckboxCircleFill,
    RiLock2Line,
    RiCheckFill,
    RiCloseFill,
    RiRssLine,
    RiGoogleFill,
    RiFacebookFill,
    RiTwitterXFill,
    RiInstagramFill,
    RiMediumFill,
    RiLinkedinFill,
    RiListCheck,
    RiCloseLine,
    RiPlayCircleFill,
    RiLockUnlockLine,
    RiMailSendLine,
    RiSuitcaseLine,
    RiTeamLine,
    RiVipCrown2Line,
    RiUserLine,
    RiGridFill,
    RiLink,
    RiLightbulbFlashLine,
    RiPlayLine,
    RiFacebookBoxFill,
    RiLock2Fill,
    RiHomeLine,
    RiShareBoxLine,
    RiArrowDropLeftLine,
    RiArrowDropRightLine,
    RiDownload2Line,
    RiQuillPenLine,
    RiBookmarkFill,
    RiBookmarkLine,
    RiInformationLine,
    RiNewspaperLine,
    RiLineChartLine,
    RiCalendarEventLine,
    RiBook3Line,
    RiFlaskLine,
    RiHeadphoneFill,
    RiGlobalLine,
    RiMoreFill,
    RiSearchLine,
    RiArrowRightUpLine,
    RiArrowRightDownLine,
    RiPentagonLine,
    RiPentagonFill,
    RiVipCrown2Fill,
    RiAddFill,
} from 'react-icons/ri'
import styled from 'styled-components'

import type { SizeProps } from '../types/props'

import { LegacyButton as Button } from 'components/Button'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { getColor } from 'theme'
import type { ColorName } from 'theme'

/**
 * This may seem a little verbose, to import each icon the application requires separately.
 * However, using `import * as RiIcons` results in a 1mb bundle.
 */

const RiIcons = {
    RiCalendar2Line,
    RiCheckboxBlankCircleLine,
    RiArrowLeftLine,
    RiArrowRightLine,
    RiArrowRightSLine,
    RiArrowDownSLine,
    RiArrowRightUpLine,
    RiArrowRightDownLine,
    RiArticleLine,
    RiBookOpenLine,
    RiStarFill,
    RiAppleFill,
    RiAndroidFill,
    RiCheckboxCircleFill,
    RiLock2Line,
    RiCheckFill,
    RiCloseFill,
    RiRssLine,
    RiGoogleFill,
    RiFacebookFill,
    RiTwitterXFill,
    RiInstagramFill,
    RiMediumFill,
    RiLinkedinFill,
    RiListCheck,
    RiCloseLine,
    RiPlayCircleFill,
    RiLockUnlockLine,
    RiMailSendLine,
    RiSuitcaseLine,
    RiTeamLine,
    RiVipCrown2Line,
    RiUserLine,
    RiGridFill,
    RiLightbulbFlashLine,
    RiPlayLine,
    RiLink,
    RiFacebookBoxFill,
    RiLock2Fill,
    RiHomeLine,
    RiShareBoxLine,
    RiArrowDropLeftLine,
    RiArrowDropRightLine,
    RiDownload2Line,
    RiQuillPenLine,
    RiBookmarkFill,
    RiBookmarkLine,
    RiInformationLine,
    RiNewspaperLine,
    RiLineChartLine,
    RiCalendarEventLine,
    RiBook3Line,
    RiFlaskLine,
    RiHeadphoneFill,
    RiGlobalLine,
    RiMoreFill,
    RiSearchLine,
    RiPentagonLine,
    RiPentagonFill,
    RiAddFill,
    RiVipCrown2Fill,
}

export type IconKeys = keyof typeof RiIcons

type LocalIconProps = {
    icon: IconKeys
    /** can pass in px or rem string values to make custom sizes */
    size: SizeProps | string
    title?: string
    stroke?: ColorName
    strokeWidth?: IconBaseProps['strokeWidth']
}

/*

    We now use react-icons to import the icons, specifically the remix icon sub-module.

    To find the relevant icon, go here:

    https://react-icons.github.io/react-icons/icons/ri/

    clicking on the icon will copy the string to the clipboard,
    and then pass in the name via the 'icon' prop

    e.g.
    <Icon icon="RiCheckboxBlankCircleLine" color="primary" />

*/

export type IconStyleProps = Omit<BoxProps, 'width' | 'height'>
export type IconProps = LocalIconProps & IconStyleProps

const Icon = ({
    color,
    className,
    icon,
    size,
    title,
    onClick,
    stroke,
    strokeWidth,
    ...props
}: IconProps) => {
    const IconInUse = RiIcons[icon]

    const sizeProp = IconSizeProperties[size] || size

    const strokeColor = getColor(stroke) ?? undefined

    const iconComponent = (
        <_Icon
            as="svg"
            role="img"
            width={sizeProp}
            height={sizeProp}
            color={getColor(color)}
            className={className}
            {...props}
        >
            {!!title && <title>{title}</title>}
            {IconInUse && (
                <IconInUse size={sizeProp} stroke={strokeColor} strokeWidth={strokeWidth} />
            )}
        </_Icon>
    )

    return onClick ? (
        <Button isLink onClick={onClick}>
            {iconComponent}
        </Button>
    ) : (
        iconComponent
    )
}

export const iconDefaultProps: Partial<IconProps> = {
    size: 'default',
    color: 'grey5',
}

Icon.defaultProps = iconDefaultProps

export default Icon

const IconSizeProperties: Record<string, number> = {
    xsmall: 12,
    small: 16,
    default: 24,
    medium: 36,
    large: 48,
}

const _Icon = styled(Box)<IconProps>`
    fill: ${(props: IconProps) => getColor(props.color)};
`
