import React from 'react'

import NextLink from 'next/link'
import styled, { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import { fonts } from 'theme'

type LocalLinkProps = {
    children: React.ReactNode
    target?: string
    underline?: boolean
    inline?: boolean
    url: string | { pathname: string; query?: { [key: string]: any } }
}

export type LinkProps = LocalLinkProps & BoxProps

export const isExternalLink = (pathname?: string) =>
    pathname ? /^(http|mailto:)/.test(pathname) : false

/**
 * Parses props into a clean state to be used in the Link component. Abstracted out of the component for sanity.
 */
const useParseLinkProps = ({ url, target, ...props }: LinkProps) => {
    let pathname = ''

    if (typeof url !== 'string') {
        if (url?.pathname) {
            pathname = url.pathname
        }
    } else {
        pathname = url
    }

    if (!pathname) {
        console.warn('<Link/> component requires a valid "url={}" prop')
    }

    const _target = isExternalLink(pathname) ? '_blank' : target
    const rel = _target === '_blank' ? 'noopener noreferrer' : undefined

    return { pathname, target: _target, rel, ...props }
}

export const Link = (linkProps: LinkProps) => {
    const {
        children,
        className,
        pathname,
        rel,
        target,
        onClick,
        underline,
        inline,
        ...boxStyleProps
    } = useParseLinkProps(linkProps)

    return (
        <StyledNextLink
            href={pathname}
            target={target}
            rel={rel}
            className={className}
            onClick={onClick}
            $inline={inline}
            style={{ textDecoration: underline ? 'underline' : 'none' }}
            {...boxStyleProps}
        >
            {children}
        </StyledNextLink>
    )
}

export default Link

type StyledNextLinkProps = {
    $inline: LocalLinkProps['inline']
} & LinkProps

const StyledNextLink = styled(NextLink)<StyledNextLinkProps>`
    cursor: pointer;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: ${props =>
        !!props?.fontWeight ? String(props?.fontWeight) : fonts.weight.semibold};

    &:hover {
        text-decoration: underline;
    }

    ${props =>
        props?.$inline &&
        css`
            display: inline-block;
        `}
`
