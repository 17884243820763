import React from 'react'

import NextJsLink from 'next/link'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'

type LocalLinkProps = {
    children: React.ReactNode
    target?: string
    underline?: boolean
    url: string | { pathname: string; query?: { [key: string]: any } }
}

export type LinkProps = LocalLinkProps & BoxProps

const isExternalLink = (pathname?: string) => (pathname ? /^(http|mailto:)/.test(pathname) : false)

/**
 * @deprecated  Use Link or LinkWrapper where possible
 * This is a component which contains the logic for rendering a link to be used when
 * you want to use a link as an "as" prop on another component (e.g. a button).
 */
export const UnstyledLink = ({
    children,
    className,
    url,
    target,
    onClick,
    as = 'a',
    ...props
}: LinkProps) => {
    let pathname: string | undefined

    if (typeof url !== 'string') {
        if (url?.pathname) {
            pathname = url.pathname
        }
    } else {
        pathname = url
    }

    if (!pathname) {
        console.warn('<Link/> component requires a valid "url={}" prop')
    }

    if (isExternalLink(pathname)) {
        return (
            <Box
                href={pathname}
                target="_blank"
                rel="noopener noreferrer"
                className={className}
                onClick={onClick}
                as={as}
                {...props}
            >
                {children}
            </Box>
        )
        // Passing an 'onClick' prop will not allow us to take advantage of NextJsLink
        // and may cause performance issues/ slow renders when navigating between pages
    } else if (onClick) {
        return (
            <Box
                href={pathname}
                target={target}
                rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                className={className}
                onClick={onClick}
                as={as}
                {...props}
            >
                {children}
            </Box>
        )
    } else {
        return (
            <NextJsLink passHref href={url} legacyBehavior>
                <Box
                    target={target}
                    rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                    className={className}
                    as={as}
                    {...props}
                >
                    {children}
                </Box>
            </NextJsLink>
        )
    }
}

export default UnstyledLink
