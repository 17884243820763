import type { CSSProperties } from 'react'

import type { ImageProps as NextImageProps } from 'next/image'
import NextImage from 'next/image'
import { ErrorBoundary } from 'react-error-boundary'

import { LegacyButton as Button } from 'components/Button'
import { LegacyLink as Link } from 'components/Link'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { Prettify } from 'types/Prettify'

type LocalImageProps = {
    href?: string
    fallbackSrc?: ImageProps['src']
    objectFit?: CSSProperties['objectFit']
}

type ImportedProps = 'layout' | 'alt' | 'objectPosition' | 'src' | 'priority' | 'fill'

type UglyImageProps = Pick<NextImageProps, ImportedProps> &
    Omit<BoxProps, 'objectFit' | ImportedProps> &
    LocalImageProps

export type ImageProps = Prettify<UglyImageProps>

const Image = ({
    src,
    className,
    alt,
    onClick,
    href,
    objectFit,
    priority,
    fallbackSrc,
    fill,
    ...containerStyle
}: ImageProps) => {
    // We need to use this with the new "fill" prop when we don't define width & height
    const _fill = fill ? fill : objectFit === 'cover' ? true : false

    const imageComponent = (
        <Box overflow="hidden" {...containerStyle}>
            <NextImage
                src={src}
                alt={alt}
                className={className}
                priority={priority}
                fill={_fill}
                style={{ objectFit: objectFit }}
            />
        </Box>
    )

    if (onClick)
        return (
            <Button isLink onClick={onClick}>
                {imageComponent}
            </Button>
        )

    if (href)
        return (
            <Link url={href} target="_blank">
                {imageComponent}
            </Link>
        )

    if (!fallbackSrc) return imageComponent

    return (
        <ErrorBoundary
            fallbackRender={() => (
                <Box overflow="hidden" {...containerStyle}>
                    <NextImage
                        src={fallbackSrc}
                        alt={alt}
                        className={className}
                        priority={priority}
                        fill={fill}
                        style={{ objectFit: objectFit }}
                    />
                </Box>
            )}
        >
            {imageComponent}
        </ErrorBoundary>
    )
}

export default Image
