import { ALLOWANCE_FINISHED_COOKIE_NAME } from 'lib/freeArticleAllowance/consts'

//https://docs.aws.amazon.com/waf/latest/developerguide/waf-tokens-details.html
// Should be moved alongside WAF module/logic if we create one at some point
const AWS_WAF_COOKIE_NAME = 'aws-waf-token'

// Add any cookie names we want to pass onto
// Django here. Seems sensible to whitelist rather
// than blacklist here, but could change if we wanted
export const cookiesToProxyToBackend = [ALLOWANCE_FINISHED_COOKIE_NAME, AWS_WAF_COOKIE_NAME]
