import type { useEnrollInExperiment_viewer$data } from '__generated__/useEnrollInExperiment_viewer.graphql'


// Used to whitelist cookie
export const EXPERIMENTS_PREFIX = 'finexp_'

/*

    useExperiments

    Simple hook that is purely designed to get the variant the user is enrolled in
    given a list of experiments.
    Deciding what variant to show in the UI based on this (and tracking) is delegated elsewhere.

    Careful: this experiment framework was ported from oban.
    Due to a difference in architecture, we need to fetch experiments
    on the page where they're used and pass them here.

*/

export type Variant = useEnrollInExperiment_viewer$data['experiments'][0]['variant']

const useExperiments = (viewer: useEnrollInExperiment_viewer$data) => {
    function getVariantFor(experimentName: string): Variant | null {
        const experiments = viewer.experiments || []
        const _experiment = experiments.find(e => e.name === experimentName)
        if (_experiment) {
            return _experiment.variant
        }

        return null
    }

    return {
        getVariantFor,
    }
}

export default useExperiments
