import styled, { css } from 'styled-components'

type Highlight = 'none' | 'hover' | 'always'
type Props = {
    /**
     * Indicates when to highlight the text
     *
     * @default always
     */
    $highlight?: Highlight
}

const styles = css`
    transition: background 300ms ease;
    background: ${({ theme }) => css`linear-gradient(
            100deg,
            transparent 5px,
            
            ${theme.palette && theme.palette.highlight[500]} 5px,
            ${theme.palette && theme.palette.highlight[500]} calc(100% - 5px),
            transparent calc(100% - 5px)
        );`};
    background-repeat: repeat-x;
    background-size: 100% 100%;
`

/**
 * Wrap around text to highlight.
 *
 * Highlighting can happen always, on hover or none.
 *
 * If you need the highlighting to be triggered by a parent, you can import
 * {applyTextHighlightingStyles} and add then to a parent styled component.
 */
const HighlightText = styled.span<Props>`
    padding: 0 0.5rem;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    ${({ $highlight }) => {
        switch ($highlight) {
            case 'none':
                return
            case 'hover':
                return css`
                    &:hover {
                        ${styles}
                    }
                `
            case 'always':
            default:
                return styles
        }
    }}
`

/**
 * Apply these styles in the parent to trigger from a parent.
 */
export const applyTextHighlightingStyles = css`
    ${HighlightText} {
        ${styles}
    }
`

export default HighlightText
