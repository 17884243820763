import type { Environment } from 'relay-runtime'

import {
    APP_STORE_CLICK,
    COMPLETE_APP_REGISTRATION,
    COMPLETE_REGISTRATION,
    NEWSLETTER_EMAIL_SUBMITTED,
    PURCHASE,
    SEARCH,
    START_TRIAL,
    SUBSCRIBE,
} from './consts'
import fbClient from './fbTrackingClient'
import type {
    AppStoreClickProps,
    CompleteRegistration,
    ConversionsAPIUserProps,
    Purchase,
    Search,
    StartTrial,
    Subscribe,
} from './types'

// Standard FB Conversions API Events

export const trackSubscribe = (
    environment: Environment,
    data: Subscribe & ConversionsAPIUserProps,
) => {
    fbClient.trackConversionsAPIEvent(environment, SUBSCRIBE, data)
}

export const trackSearch = (environment: Environment, data: Search & ConversionsAPIUserProps) => {
    fbClient.trackConversionsAPIEvent(environment, SEARCH, data)
}

export const trackPurchase = (
    environment: Environment,
    data: Purchase & ConversionsAPIUserProps,
) => {
    fbClient.trackConversionsAPIEvent(environment, PURCHASE, data)
}

export const trackStartTrial = (
    environment: Environment,
    data: StartTrial & ConversionsAPIUserProps,
) => {
    fbClient.trackConversionsAPIEvent(environment, START_TRIAL, data)
}

export const trackNewsletterSignupCompleted = (
    environment: Environment,
    data: ConversionsAPIUserProps,
) => {
    fbClient.trackConversionsAPIEvent(environment, COMPLETE_REGISTRATION, {
        value: 2,
        currency: 'usd',
        ...data,
    })
}

// Custom FB Events
// TODO: Migrate to Conversions API

export const trackCompleteRegistration = (data: CompleteRegistration) => {
    fbClient.trackCustom(COMPLETE_APP_REGISTRATION, data)
}

export const trackAppStoreClick = (data: AppStoreClickProps) => {
    fbClient.trackCustom(APP_STORE_CLICK, data)
}

export const trackNewsletterEmailSubmitted = () => {
    fbClient.trackCustom(NEWSLETTER_EMAIL_SUBMITTED, { value: 2, currency: 'usd' })
}

export const trackPageView = () => {
    fbClient.pageView()
}
