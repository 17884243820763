import { useEffect, useRef } from 'react'

import type { DialogProps } from 'reakit/Dialog'
import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import styled, { css } from 'styled-components'

import { colors, zIndex } from 'theme'

type LocalModalProps = {
    children: React.ReactNode
    ariaLabel: string
}

export type ModalProps = LocalModalProps & DialogProps

const Modal = ({ children, ariaLabel, className, ...modalState }: ModalProps) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (modalState.visible) {
            ref.current && ref.current.focus()
        }
    }, [modalState.visible])

    if (!modalState.visible) return null

    return (
        <>
            <_DialogBackdrop {...modalState} {...{ className }} />
            <_Dialog {...modalState} {...{ className }} aria-label={ariaLabel} ref={ref}>
                {children}
            </_Dialog>
        </>
    )
}

export default Modal

const _Dialog = styled(Dialog)<any>`
    ${(props: any) =>
        props.visible &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: ${zIndex.layerTop};

            :focus {
                outline: none;
            }
        `}
`

const _DialogBackdrop = styled(DialogBackdrop)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${zIndex.layer09};
    background-color: ${colors.modalBackground}; // TODO TOMMY - transparent
`
