import { isProductionEnvironment } from 'utils/isProductionEnvironment'

class TikTokTrackingClient {
    isAuthorized = false

    authorize = () => {
        // Comment out the following line to enable TikTok tracking in development / staging
        if (!isProductionEnvironment) return
        
        if (this.isAuthorized) return
        this.isAuthorized = true

        // Append script to DOM
        const script = document.createElement('script')
        script.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=BTBMDAT7FNQBGI7RB2J0'
        script.type = 'text/javascript'
        script.async = true
        document.head.append(script)
    }
}

const tikTokTrackingClient = new TikTokTrackingClient()
export default tikTokTrackingClient
