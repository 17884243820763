import { useCallback, useState } from 'react'

import constate from 'constate'

const useIsNavCollapsed = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(false)

    const toggleIsNavCollapsed = useCallback(
        () => setIsNavCollapsed(!isNavCollapsed),
        [setIsNavCollapsed, isNavCollapsed],
    )

    return { isNavCollapsed, toggleIsNavCollapsed }
}

export const [NavBarProvider, useIsNavCollapsedContext] = constate(useIsNavCollapsed)
