import type { Stripe } from '@stripe/stripe-js'
// must be /pure import so it does not load the stripe script until loadStripe() is called
// https://www.npmjs.com/package/@stripe/stripe-js#:~:text=If%20you%20would%20like%20to%20use%20loadStripe%20in%20your%20application%2C%20but%20defer%20loading%20the%20Stripe.js%20script%20until%20loadStripe%20is%20first%20called%2C%20use%20the%20alternative%20%40stripe/stripe%2Djs/pure%20import%20module%3A
import { loadStripe } from '@stripe/stripe-js/pure'

const stripeToken = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_TOKEN
let stripePromise: Promise<Stripe | null>

const initialiseStripe = () => {
    if (!stripeToken) {
        throw new Error('Missing Stripe token')
    }

    if (!stripePromise) {
        stripePromise = loadStripe(stripeToken)
    }
    return stripePromise
}

export default initialiseStripe
