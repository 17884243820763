import { colors } from '../Colors'

export type DefaultPalette = typeof defaultPalette

export const defaultPalette = {
    primary: {
        900: colors.day[900],
        800: colors.day[800],
        700: colors.day[700],
        600: colors.day[600],
        500: colors.day[500],
        400: colors.day[400],
        300: colors.day[300],
        200: colors.day[200],
        100: colors.day[100],
        50: colors.day[50],
    },
    secondary: {
        900: colors.night[900],
        800: colors.night[800],
        700: colors.night[700],
        600: colors.night[600],
        500: colors.night[500],
        400: colors.night[400],
        300: colors.night[300],
        200: colors.night[200],
        100: colors.night[100],
        50: colors.night[50],
    },
    accent: {
        900: colors.azure[900],
        800: colors.azure[800],
        700: colors.azure[700],
        600: colors.azure[600],
        500: colors.azure[500],
        400: colors.azure[400],
        300: colors.azure[300],
        200: colors.azure[200],
        100: colors.azure[100],
        50: colors.azure[50],
    },
    highlight: {
        900: colors.starship[900],
        800: colors.starship[800],
        700: colors.starship[700],
        600: colors.starship[600],
        500: colors.starship[500],
        400: colors.starship[400],
        300: colors.starship[300],
        200: colors.starship[200],
        100: colors.starship[100],
        50: colors.starship[50],
    },
    neutral: {
        999: colors.concrete[999],
        900: colors.concrete[900],
        800: colors.concrete[800],
        700: colors.concrete[700],
        600: colors.concrete[600],
        500: colors.concrete[500],
        400: colors.concrete[400],
        300: colors.concrete[300],
        200: colors.concrete[200],
        100: colors.concrete[100],
        50: colors.concrete[50],
        0: colors.concrete[0],
    },
    info: {
        900: colors.lavender[900],
        800: colors.lavender[800],
        700: colors.lavender[700],
        600: colors.lavender[600],
        500: colors.lavender[500],
        400: colors.lavender[400],
        300: colors.lavender[300],
        200: colors.lavender[200],
        100: colors.lavender[100],
        50: colors.lavender[50],
    },
    success: {
        900: colors.green[900],
        800: colors.green[800],
        700: colors.green[700],
        600: colors.green[600],
        500: colors.green[500],
        400: colors.green[400],
        300: colors.green[300],
        200: colors.green[200],
        100: colors.green[100],
        50: colors.green[50],
    },
    warning: {
        900: colors.yellow[900],
        800: colors.yellow[800],
        700: colors.yellow[700],
        600: colors.yellow[600],
        500: colors.yellow[500],
        400: colors.yellow[400],
        300: colors.yellow[300],
        200: colors.yellow[200],
        100: colors.yellow[100],
        50: colors.yellow[50],
    },
    destructive: {
        900: colors.red[900],
        800: colors.red[800],
        700: colors.red[700],
        600: colors.red[600],
        500: colors.red[500],
        400: colors.red[400],
        300: colors.red[300],
        200: colors.red[200],
        100: colors.red[100],
        50: colors.red[50],
    },
}
