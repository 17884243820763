import Pixel from 'react-facebook-pixel'
import type { Environment } from 'relay-runtime'

import type { FBConversionsAPIData, FBConversionsAPIEventName } from './types'

import trackFbConversionsAPIEvent from 'relay/mutations/trackFbConversionsAPIEvent'
import { captureException } from 'shared/sentry'
import { isProductionEnvironment } from 'utils/isProductionEnvironment'

const IS_BROWSER = typeof window !== 'undefined'

const facebookPixelId = process.env.NEXT_PUBLIC_FB_PIXEL_ID
class FBTrackingClient {
    // NOTE All methods on this class which contact the API MUST be gated
    // with a check on `isAuthorized`. Similarly all setup code can only
    // be run in the `authorize` method which configures this property.

    isAuthorized = false

    authorize = () => {
        // Comment out the following line to enable FB tracking in development / staging
        if (!isProductionEnvironment) return
        
        if (this.isAuthorized) return // noop
        this.isAuthorized = true

        // @ts-ignore
        if (IS_BROWSER && !window.FB_INITIALIZED) {
            if (!facebookPixelId) {
                throw new Error('process.env.NEXT_PUBLIC_FB_PIXEL_ID is not defined')
            }

            Pixel.init(facebookPixelId)
        }
    }

    _handleEvent = (trackingFn: Function) => {
        if (!this.isAuthorized) return
        try {
            trackingFn()
        } catch (e) {
            captureException(e)
        }
    }

    track = (eventName: string, data: { [key: string]: any }) => {
        if (!this.isAuthorized) return
        const event = () => Pixel.track(eventName, data)
        this._handleEvent(event)
    }

    trackCustom = (eventName: string, data: { [key: string]: any }) => {
        if (!this.isAuthorized) return
        const event = () => Pixel.trackCustom(eventName, data)
        this._handleEvent(event)
    }

    pageView = () => {
        if (!this.isAuthorized) return
        const event = () => Pixel.pageView()
        this._handleEvent(event)
    }

    trackConversionsAPIEvent = async (
        environment: Environment,
        eventName: FBConversionsAPIEventName,
        data: FBConversionsAPIData,
    ) => {
        if (!this.isAuthorized) return
        try {
            await trackFbConversionsAPIEvent(environment)({
                ...data,
                eventName,
            })
        } catch {}
    }
}

const facebookTrackingClient = new FBTrackingClient()
export default facebookTrackingClient
