/*

    Put the config in this file so we can share
    this between files/components w/o circular refs

*/

// cookie names
export const ARTICLE_IDS_COOKIE_NAME = '__laga__art_ids'
export const DATE_WINDOW_STARTED_COOKIE_NAME = '__laga__art_window_started'
export const ALLOWANCE_FINISHED_COOKIE_NAME = '__laga__art_elapsed'
export const MAX_FREE_CONTENT_PIECES_ALLOWANCE_COOKIE_NAME = '__laga__max_free_content_pieces_allowance'
export const MAX_FREE_CONTENT_PIECES_ALLOWANCE_EXPERIMENT_NAME = 'max_free_content_pieces_allowance'
export const MAX_FREE_CONTENT_PIECES_ALLOWANCE_DEFAULT_VARIANT = 'default'

// How long the free window lasts:
export const ARTICLE_WINDOW_MONTHS = 3
// How many free articles in that window:
export const FREE_ARTICLES_ALLOWED_EACH_WINDOW = 3
// How many months to opt a user in for (in theory this could be forever,
// but perhaps make it shorter to reduce impact of bugs/old experiments etc)
export const MONTHS_OPTED_IN = 4
