
import type { Environment } from 'relay-runtime'

import { getCookie } from 'lib/useCookies'
import trackAppsFlyerPBAEventMutation from 'relay/mutations/trackAppsFlyerPBAEvent'

export const AF_COMPLETE_REGISTRATION = 'af_complete_registration'
export const AF_LOGIN = 'af_login'
export const AF_TRIAL = 'af_start_trial'
export const AF_MONTHLY_SUBSCRIPTION = 'af_subscribe_monthly'
export const AF_YEARLY_SUBSCRIPTION = 'af_subscribe_yearly'

type AFEventName = typeof AF_COMPLETE_REGISTRATION | typeof AF_LOGIN | typeof AF_TRIAL | typeof AF_MONTHLY_SUBSCRIPTION | typeof AF_YEARLY_SUBSCRIPTION

type AFData = {
    customerUserId: string
    afRevenue?: number
    afCurrency?: string
}

export type AppsFlyerPBATrackingFn = (
    environment: Environment,
    eventName: AFEventName,
    data: AFData,
) => Promise<void>

const AF_USER_ID_COOKIE = 'afUserId'

/**
 * This class is used to manage the AppsFlyer Web PBA (People-based Attribution) SDK.
 * The SDK works via a script added in _app.tsx, but events are only being sent after 
 * the user has given consent in the cookie modal.
 * 
 * Other than enabling measurement, the subsequent steps (i.e. setting the Customer User ID and sending events)
 * are handled in chivas via the TrackAppsFlyerPBAEvent mutation.
 */
class AppsFlyerPBAClient {
    isAuthorized = false
    afUserId = getCookie(AF_USER_ID_COOKIE)

    /**
     * This method is called when the user has given consent to tracking, which should only happen in CookieModal.
     */
    authorize = () => {
        if (this.isAuthorized) return
        this.isAuthorized = true
        // Enable measurement only after the user has given consent in the cookie modal
        // @ts-ignore
        if (window.AF_SDK) {
            // @ts-ignore
            window.AF_SDK.PLUGINS.PBA.enableMeasurement()
        }
    }

    /**
     * Sends a mutation to our backend with the event name and data. The backend then sends the event to AF.
     */
    track: AppsFlyerPBATrackingFn = async (environment, eventName, data) => {
        if (!this.isAuthorized) return
        // Do not send an event if the Customer User ID or AF User ID is missing
        if (!data.customerUserId || !this.afUserId) return

        await trackAppsFlyerPBAEventMutation(environment)({
            ...data,
            afUserId: this.afUserId,
            eventName,
        })
    }
}

const appsFlyerPBAClient = new AppsFlyerPBAClient()
export default appsFlyerPBAClient