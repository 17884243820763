import type { ReactElement } from 'react';
import React from 'react'

import type { StaticImageData } from 'next/image'

import Image from 'components/Image'
import Paragraph from 'components/Paragraph'
import type { BoxProps } from 'components/Primitives/Box';
import Box from 'components/Primitives/Box'
import StarRating from 'components/StarRating'

interface Props {
    review: string
    rating?: number
    title?: string
    showYellowStars?: boolean
    boxStyle?: BoxProps
    avatar: StaticImageData
}

export const ReferralReviewCard = (props: Props): ReactElement => {
    const { review, rating = 5, title, showYellowStars = true, boxStyle = {} } = props
    return (
        <Box
            backgroundColor="grey300"
            borderRadius="default"
            padding={['2rem', null, null, null]}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={['85vw', '85vw', '45vw', '350px', '350px']}
            {...boxStyle}
        >
            <Box padding={0} justifyContent="center">
                <Image
                    src={props.avatar}
                    alt="avatar"
                    width="65px"
                    height="65px"
                    layout="fill"
                    position="relative"
                    objectFit="cover"
                    borderRadius="rounded"
                />
            </Box>

            <StarRating
                rating={rating}
                color={showYellowStars ? 'warning.500' : undefined}
                mt={'10px'}
                mb={'10px'}
                justifyContent={'center'}
                starSize="18px"
            />
            <Box flex={1} textAlign="center" width={'100%'}>
                {title && (
                    <Paragraph color="black800" fontWeight={600} fontSize={22}>
                        {title}
                    </Paragraph>
                )}
                <Paragraph color="black800" fontSize={16}>
                    {review}
                </Paragraph>
            </Box>
        </Box>
    )
}
