import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { trackAppsFlyerPBAEventMutationResponse } from '__generated__/trackAppsFlyerPBAEventMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

type InputObject = {
    customerUserId: string
    afUserId: string
    eventName: string
    afRevenue?: number
    afCurrency?: string
}

const trackAppsFlyerPBAEventMutation = (relayEnv: Environment) => (input: InputObject) => {
    return createMutationPromise<trackAppsFlyerPBAEventMutationResponse>(relayEnv)({
        // @ts-ignore
        mutation: graphql`
            mutation trackAppsFlyerPBAEventMutation($input: TrackAppsflyerPBAEventInput!) {
                trackAppsflyerPbaEvent(input: $input) {
                    clientMutationId
                }
            }
        `,
        variables: { input },
    })
}

export default trackAppsFlyerPBAEventMutation
