import React from 'react'

import Icon from 'components/Icon'
import type { IconProps } from 'components/Icon'
import type { BoxProps } from 'components/Primitives/Box'
import Set from 'components/Set'
import type { ColorName } from 'theme/Types'

export type StarRatingProps = {
    rating?: number
    color?: ColorName
    secondaryColor?: ColorName
    starSize?: IconProps['size']
} & BoxProps

const StarRating = ({
    rating = 5,
    color = 'yellow',
    secondaryColor = 'grey300',
    starSize = '16px',
    ...rest
}: StarRatingProps) => (
    <Set spacing={0.25} {...rest}>
        {Array(5)
            .fill(1)
            .map((_, i) => (
                <Icon
                    key={i}
                    icon="RiStarFill"
                    size={starSize}
                    color={i > rating ? secondaryColor : color}
                />
            ))}
    </Set>
)

export default StarRating
