import type { ElementType } from 'react'

import UnstyledLink from './UnstyledLink'

import type { BoxProps } from 'components/Primitives/Box'
import { fonts } from 'theme'

type LocalLinkProps = {
    children: React.ReactNode
    target?: string
    underline?: boolean
    as?: ElementType
    url: string | { pathname: string; query?: { [key: string]: any } }
}

export type LinkProps = LocalLinkProps & BoxProps

export const isExternalLink = (pathname?: string) =>
    pathname ? /^(http|mailto:)/.test(pathname) : false

/**
 * @deprecated  Use Link where possible
 */
export const LegacyLink = ({ color = 'primary.500', as = 'a', ...props }: LinkProps) => {
    return (
        <UnstyledLink
            {...props}
            style={{
                textDecoration: props.underline ? 'underline' : 'none',
                cursor: 'pointer',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                fontWeight: fonts.weight.semibold,
            }}
            as={as}
            color={color}
        />
    )
}

export default LegacyLink
