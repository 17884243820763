import type { NextPageContext } from 'next'

export enum StatusCodeEnum {
    /**
     * The requested resource has been assigned a new permanent URI and any future references to this resource SHOULD use one of the returned URIs.
     */
    PERMANENTLY_MOVED = 301,
    /**
     * The requested resource resides at a different location, but the redirection MAY be altered on occasion when the client follows a link to the original resource.
     */
    OTHER = 303,
    NOT_FOUND = 404,
}

type QueryStringType = Record<string, string | string[] | undefined>

// copy querystring variables from next context to the url redirect
// copied from https://github.com/fridays/next-routes/blob/master/src/index.js#L172
export const toQuerystring = (obj: QueryStringType) =>
    Object.keys(obj)
        .filter(key => obj[key] !== null && obj[key] !== undefined)
        .map(key => {
            let value = obj[key] || ''

            if (Array.isArray(value)) {
                value = value.join('/')
            }
            return [encodeURIComponent(key), encodeURIComponent(value)].join('=')
        })
        .join('&')

export const authRedirect = (context: NextPageContext, target: string) => {
    const query: QueryStringType = context.query

    // don't know why we need to do this for Router.push, but not other router fns
    // res.writeHead seems to also need it to handle slugs
    const formattedTarget = !target.startsWith('/') && target !== '' ? '/' + target : target

    const location =
        typeof query === 'object'
            ? `${formattedTarget}?${toQuerystring(query || {})}`
            : formattedTarget

    redirect(context, location, StatusCodeEnum.OTHER)
}

export const redirect = (context: NextPageContext, target: string, statusCode: StatusCodeEnum) => {
    // don't know why we need to do this for Router.push, but not other router fns
    // res.writeHead seems to also need it to handle slugs
    const formattedTarget = !target.startsWith('/') && target !== '' ? '/' + target : target

    if (context.res) {
        // server
        context.res.writeHead && context.res.writeHead(statusCode, { Location: formattedTarget })
        context.res.end && context.res.end()
    } else {
        window.location.href = formattedTarget
    }
}
