import { useState, useCallback } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import constate from 'constate'

import type { EnvSpecificConfig } from './types'
import { DatadogEnv } from './types'

import { isProductionBuild } from 'utils/isProductionBuild'
import { isProductionEnvironment } from 'utils/isProductionEnvironment'

const APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || ''
const CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || ''

if (!APPLICATION_ID || !CLIENT_TOKEN) {
    throw new Error('Datadog Client has not found the appropriate tokens.')
}

declare global {
    interface Window {
        DD_RUM: any
    }
}

const envSpecificConfig: Record<DatadogEnv, EnvSpecificConfig> = {
    // By default, we don't track anything in dev.
    // Uncomment the isProductionBuild check in the authorize method and change the config values to enable tracking in dev.
    [DatadogEnv.DEV]: {
        sessionSampleRate: 0, // Increase this to test locally
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        trackUserInteractions: false,
        trackFrustrations: false,
        trackResources: false,
        trackLongTasks: false,
    },
    [DatadogEnv.STAGING]: {
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
    },
    [DatadogEnv.PRODUCTION]: {
        sessionSampleRate: 20,
        // Session replay was only ever intended to be used for B2B users. Initial implementation here before turning off entirely https://github.com/finimize/lagavulin/pull/1513/files
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
    },
}

/**
 * This hook is used to enable Datadog monitoring. It is initialized in CookieModal, after the user has given consent to tracking.
 *
 * Use it as follows:
 * - get the client: datadogClient = useDatadogContext()
 * - call the authorize method: datadogClient.authorize()
 */
const useDatadogMonitoring = () => {
    const [isAuthorized, setIsAuthorized] = useState(false)
    const datadogEnv = (process.env.NEXT_PUBLIC_DATADOG_ENV as DatadogEnv) || DatadogEnv.DEV

    const getEnvSpecificConfig = useCallback(() => {
        return envSpecificConfig[datadogEnv]
    }, [datadogEnv])

    /**
     * This method is called when the user has given consent to tracking, which should only happen in CookieModal.
     * It sets the `isAuthorized` flag to true, which allows us to set cookies and send data to DD.
     */
    const authorize = useCallback(() => {
        // Comment out the following line to enable Datadog monitoring in development env
        if (!isProductionBuild) return

        // Before datadog has been initialized, window.DD_RUM.getInitConfiguration() is undefined.
        // This check prevents a suspected react state race condition where we can attempt to initialize datadog twice
        const isDataDogInitialized = !!window?.DD_RUM?.getInitConfiguration?.()

        if (isAuthorized || isDataDogInitialized) return
        setIsAuthorized(true)

        const envSpecificConfig = getEnvSpecificConfig()

        // Adding the 'v' so we adhere to DD tagging rules: (https://docs.datadoghq.com/getting_started/tagging/)
        const VERSION = isProductionEnvironment
            ? `v${process.env.INCREMENTED_VERSION}`
            : process.env.NEXT_PUBLIC_BASE_DOMAIN

        datadogRum.init({
            applicationId: APPLICATION_ID,
            clientToken: CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'finimize-web',
            env: datadogEnv,
            version: VERSION || 'unknown',
            defaultPrivacyLevel: 'mask-user-input',
            ...envSpecificConfig,
        })
    }, [isAuthorized])

    return { authorize }
}

export const [DatadogProvider, useDatadogContext] = constate(useDatadogMonitoring)
