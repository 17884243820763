import React from 'react'

import styled, { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

type LocalParagraphProps = {
    children: string | (React.ReactNode & string) | React.ReactNode
    maxLines?: number
}

export type ParagraphProps = LocalParagraphProps & BoxProps

export const Paragraph = ({
    children,
    as = 'p',
    className,
    maxLines,
    color = 'neutral.700',
    fontWeight = fonts.weight.normal,
    lineHeight = 1.4,
    ...props
}: ParagraphProps) => {
    return (
        <_Paragraph
            as={as}
            className={className}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            maxLines={maxLines}
            color={color}
            {...props}
        >
            {children}
        </_Paragraph>
    )
}

export default Paragraph

const _Paragraph = styled(Box)<ParagraphProps>`
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
    ${({ maxLines }) =>
        maxLines &&
        css`
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: ${maxLines};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        `}
`
